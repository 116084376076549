import React, {useState, useEffect, Fragment} from "react";

import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import Datafeed from './datafeed.js';
import { getData } from "../../../store/appStoreSlice";
import { getNews, placeNewOrder } from "../../../utils";


const useStyles = makeStyles((theme) => ({
  root:darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
      display: "flex",
      flexDirection: "column",
      fontFamily: "Montserrat !important",
    }
  },
  flex_vertical: {
    display: "flex",
    minHeight: "calc(100vh - 118px)",
    "@media (max-width: 767.98px)": {
        flexDirection: "column",
    },
    "@media (min-width: 1900px)": {
      maxHeight: "calc(100vh - 118px)",
      height: "calc(100vh - 118px)",
    }
  },
  logo: {
    width: "210px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    margin: "18px 0",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    width: "100%",
    display: "block",
  },
  titleAn:darkTheme => {
    return {
      textAlign: "center",
      margin: "18px 0 0 0",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "18px",
      
      width: "100%",
      display: "block",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      paddingBottom: "18px",
    }
  },
  titleBa: {
    background: "rgba(38, 152, 212, 0.10)",
    padding: "18px 14px",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",

    display: "block",
    borderBottom: "1px solid rgba(176, 203, 217, 0.5)",
  },
  thtitle: {
    textAlign: "center",
    margin: "6px",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    opacity: "0.54",
  },
  trdata: {
    textAlign: "center",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "9px",
    lineHeight: "17px",
    width: "100%",
  },
  logo_img: {
    marginTop: "57px",
  },
  content: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      minWidth: "45%",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
      }
    }
  },
  orderbook: {
    margin: "auto",
    height: "100%",
    fontSize: "9px",
    overflow: "hidden",
  },

  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField: {
    color: "#E5E5E5!important",
    "&::before": {
      borderBottom: "1px solid #E5E5E5!important",
    }
  },
  password_root: {
    marginTop: "20px!important",
    color: "#E5E5E5!important",
    width: "100%",
    "&::before": {
      borderBottom: "1px solid #E5E5E5!important",
    }
  },
  iconButton_root: {
    color: "#E5E5E5!important",
  },
  have_account: {
    marginTop: "33px",
    fontSize: "15px",
  },
  have_account_link: {
    color: "#167EE6",
    textDecoration: "none",
  },
  social_button: {
    display: "flex",
    marginTop: "45px",
    color: "#E5E5E5",
    "@media (max-width: 767.98px)": {
      flexDirection: "column",
    }
  },
  tdv: {
    width: "100%",
//    minHeight: "80vh",
    border: "1px solid rgba(176, 203, 217, 0.5)",
    borderLeft: "none",
    borderBottom: "none",
    height: "calc(100% - 168px)",
    "@media (max-width: 767.98px)": {
      minHeight: "80vh",
    }
  },
  rootorderbook: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      minWidth: "180px",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      overflow: "hidden",
      borderLeft: "none",
    }
  },  
  tdvnv166:darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      width: "100%",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      height: "166px",
      overflow: "hidden",
      borderLeft: "none",
    }
  },
  tdvn: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      width: "100%",
    }
  },
  ppp:darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      border:  darkTheme ? "1px solid #FFFFFF" : "1px solid #000000",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      padding: "8px",
      margin: "4px",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "9px",
      lineHeight: "14px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      cursor:"pointer",
    }
  },
  tabbtn: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "18px",
    textAlign: "center",
    padding: "8px 0",
    border: "1px solid rgba(176, 203, 217, 0.5)",
    width: "95px",
    cursor:"pointer",
    marginBottom: "24px",
  },
  tab: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "18px",
    textAlign: "center",
    padding: "8px 0",
    background: "rgba(176, 203, 217, 0.20)",
    border: "1px solid rgba(176, 203, 217, 0.5)",
    width: "95px",
    cursor:"pointer",
    marginTop: "24px",
  },
  tabactive: darkTheme => {
    return {
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "9px",
      lineHeight: "18px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      padding: "8px 0",
      background: "rgba(55, 236, 64, 0.60)",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      marginTop: "24px",
      width: "95px",
    }
  },

  pppactive: darkTheme => {
    return {
      background: "rgba(55, 236, 64, 0.60)",

      color: darkTheme ? "white" : "black",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      padding: "8px",
      margin: "4px",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "9px",
      lineHeight: "14px",
      textAlign: "center",
    }
  },
  pbutton:  darkTheme => {
    return {
      background: "rgba(55, 236, 64, 0.60)",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "9px",
      lineHeight: "14px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      cursor:"pointer",

      border: "1px solid rgba(176, 203, 217, 0.5)",
      padding: "8px 20px",
      margin: "14px",
    }
  },
  ptxt: {

  },
  txt:darkTheme => {
    return {
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "9px",
      lineHeight: "12px",
      color: darkTheme ? "white" : "black",
      textAlign: "left",
      textDecoration: "none",
      padding: "0 14px",
    }
  },
  txth17:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "24px",
    textAlign: "left",
  },
  greytitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
  },
  greyclicktitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
    cursor:"pointer",
  },

  grey2title:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "18px",

    width: "100%",
    textAlign: "center",
    cursor:"pointer",
  },

  activetitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "18px",
    borderBottom: "2px solid rgba(176, 203, 217, 1)",
    width: "100%",
    textAlign: "center",
  },
  inputtitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "12px",
    padding: "14px 0 0 0",
    textAlign: "right",
  },
  buy_sell:darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      minWidth: "280px",
      display: "flex",
      flexDirection: "column",
      borderTop: "1px solid rgba(176, 203, 217, 0.5)",
      borderBottom: "1px solid rgba(176, 203, 217, 0.5)",
    }
  },
  flex_v: {
    width: "100%",
    display: "flex",
    flexDirection: "column",

  },
  flex_vB: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderTop: "1px solid rgba(176, 203, 217, 0.5)",
  },
  leftyblock: {
    display: "flex",
    width: "296px",
    flexDirection: "column",
    border: "1px solid rgba(176, 203, 217, 0.5)",
    background: "rgba(38, 152, 212, 0.10)",
  },
  input: darkTheme => {
    return {
      background: "transparent",
      border: "none !important",
      color: darkTheme ? "white" : "black",
      position: "absolute",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "9px",
      top: "0",
      right: "30px",
      margin: "10px 16px",
      textAlign: "right",
      '&:focus': {
        outline: "none",
      }
    }
  },
  flex_g: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 14px",
  },
  flex_g_tab: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 14px",
    borderBottom: "1px solid rgba(176, 203, 217, 0.5)",
  },  
  flex_g_input:darkTheme => {
    return {
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "10px",
      
    }
  },
  orders: {
    width: "100%",
    
  },
  flex_forinput: {
    position: "relative",
    padding: "0 14px",
  },
  rtdvn:darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      minWidth: "280px",
      overflow: "hidden",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      height: "100%",
    }
  },
  relpoint: {
    position: "relative",
    cursor: "pointer",
  },
  redt: {
    position: "relative",
    cursor: "pointer",
    background: "rgb(247, 29, 29) none repeat scroll 0% 0%",
    position: "absolute",
    inset:"0px",
    border: "1px solid rgba(247, 29, 29, 0.6)",
    overflow: "visible",

    opacity: "0.1",
  },
  grt: {
    position: "relative",
    cursor: "pointer",
    background: "rgb(95, 170, 83) none repeat scroll 0% 0%",
    position: "absolute",
    inset:"0px",
    border: "1px solid rgba(95, 170, 83, 0.6)",
    overflow: "visible",
    width: "174.223%",
    opacity: "0.1",
  },
  top: {
    padding: "14px",
  },
  down: {
    padding: "14px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    margin: "8px",
  },
  f12r: {
    fontSize: "9px",
    fontWeight: "100",
    cursor: "pointer",
    color: "#FF3B69",
  },
  f12g: {
    fontSize: "9px",
    fontWeight: "100",
    cursor: "pointer",
    color: "#02C77A",
  },
  f14g: {
    fontSize: "12px",
    fontWeight: "800",
    cursor: "pointer",
    color: "#02C77A",
  },
  green: {

    color: "#02C77A",
  },
  red: {

    color: "#FF3B69",
  },
  f14r: {
    fontSize: "12px",
    fontWeight: "800",
    cursor: "pointer",
    color: "#FF3B69",
  },
  f14: {
    fontSize: "12px",
    fontWeight: "800",
  },
  f11: {
    fontSize: "11px",
  },
  flexgor: {
    width: "100%",
    display: "flex",
  },
  tablew100: {
    width: "100%",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "9px",
    textAlign: "center",
  },

  tableorders: {
    width: "100%",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "9px",
    lineHeight: "14px",
    textAlign: "center",
    borderCollapse: "collapse",
  },
  tableorderstitle:darkTheme => {
    return {
      textAlign: "center",
      margin: "6px",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "9px",
      lineHeight: "14px",
      
    }
  },
  w100: {
    width: "100%",
    lineHeight: "17px",
    borderCollapse: "collapse",
  },
  createButton_root: {
    marginTop: "80px!important",
    height: "45px",
    width: "100%",
    backgroundColor: "#2698D4!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "#2698D4!important",
    }
  },
}));

const Trade = (props) => {
  const { darkTheme,  bids, asks, lastTrade, buyPrice, sellPrice, pair, lastDeals, openOrders, recentOrders, lastTradeCh, coins } = useSelector(getData);


  const [activeTab, setActiveTab] = useState("sell");
  const [procent, setProcent] = useState("75");
  const [orderType, setOrderType] = useState("market");
  
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [infoprice, setInfoprice] = useState('');
  const [total, setTotal] = useState('');
  const [news, setNews] = useState([]);
  
  const [disableprice, setDisableprice] = useState(false);

  const classes = useStyles(darkTheme);
  
  const changeAmount = (newamount) => {
    setAmount(newamount);
    if (price && Number(price)>0) {
      setTotal((Number(newamount)*Number(price)));
    }
      setProcent(Math.floor(coins[pair.split('/')[0]].balance * 100 / Number(newamount)))
  }  
  const handleAmount = (e) => {
    setAmount(e.target.value);
    if (price && Number(price)>0 && e.target.value && Number(e.target.value)>0) {
      setTotal((Number(e.target.value)*Number(price)));
    }
    if (e.target.value && Number(e.target.value)>0) {
      setProcent(Math.floor(coins[pair.split('/')[0]].balance * 100 / e.target.value))
    }
  }
  const handlePrice = (e) => {
    setPrice(e.target.value);
    if (e.target.value && Number(e.target.value)>0) {
      if (amount && Number(amount)>0) {
        setTotal((Number(e.target.value)*Number(amount)));
      } else if (total && Number(total)>0) {
        setAmount((Number(total)/Number(e.target.value)));
      }
    }
  }
  
  const changePrice = (newprice) => {
    setPrice(newprice);
      if (amount && Number(amount)>0) {
        setTotal((Number(newprice)*Number(amount)));
      } else if (total && Number(total)>0) {
        setAmount((Number(total)/Number(newprice)));
      }
  }
  const handleTotal = (e) => {
    setTotal(e.target.value);
    if (price && Number(price)>0) {
      setAmount((Number(e.target.value)/Number(price)));
    }
  }

  const changePercent = (p) => {
    setProcent(p);
    if (activeTab=="sell") {
      let newamount = Number(Number(coins[pair.split('/')[0]].balance) * Number(p) / 100 )
      setAmount(newamount)
      if (price && Number(price)>0) {
        setTotal((newamount*Number(price)));
      }
    } else {
      let newtotal = Number(Number(coins[pair.split('/')[1]].balance) * Number(p) / 100 )
      setTotal(newtotal)
      if (price && Number(price)>0) {
        setAmount((newtotal/Number(price)));
      }
    }
  }

  const widgetOptions = {
    symbol: pair,
    datafeed: Datafeed,
    interval: '1D',
    container_id: 'tvchartcontainer',
    library_path: '/charting_library/',
    locale: 'en',
    disabled_features: [
            'use_localstorage_for_settings',
            ],
    enabled_features: [],
    fullscreen: false,
    autosize: true,
    theme: darkTheme ? 'Dark' : 'Light',
    timezone: "Africa/Johannesburg",
    custom_css_url: darkTheme ? '/css/customTv.css' : '/css/empty.css', 
    overrides: {
        "mainSeriesProperties.candleStyle.upColor": "#02C77A",
        "mainSeriesProperties.candleStyle.downColor": "#FF3B69",
        "mainSeriesProperties.candleStyle.borderColor": "#378658",
        "mainSeriesProperties.candleStyle.borderUpColor": "#26a69a",
        "mainSeriesProperties.candleStyle.borderDownColor": "#ef5350",
        "mainSeriesProperties.candleStyle.wickColor": "#737375",
        "mainSeriesProperties.candleStyle.wickUpColor": "#02C77A",
        "mainSeriesProperties.candleStyle.wickDownColor": "#FF3B69",
    },
  };
  
  useEffect(() => {
    if (activeTab=="sell") {
      if (price == '') { setPrice(sellPrice) }
      setInfoprice(sellPrice);
    } else {
      if (price == '') { setPrice(buyPrice) }
      setInfoprice(buyPrice);
    }

  }, [activeTab, buyPrice, sellPrice, orderType]);
  
  
  useEffect(() => {
      setDisableprice(orderType=="market")
  }, [orderType]);
  

  
  useEffect(() => {
    var widget = window.tvWidget = new window.TradingView.widget(widgetOptions);
  }, [pair, darkTheme]);
  
  useEffect(() => {
    const fetchData = async () => {
      const news = await getNews();
      setNews(news.data);
    };
    
    fetchData();
  }, []);
  
  
  
  useEffect(() => {
    console.log("openOrders", openOrders);
  }, [openOrders]);
  
  useEffect(() => {
    if (activeTab=="sell") {
      if (Number(amount) > Number(coins[pair.split('/')[0]].balance)) {
        setAmount(coins[pair.split('/')[0]].balance);
      }
    }
  }, [amount]);
    
  
  useEffect(() => {
    if (activeTab=="buy") {
      if (Number(total) > Number(coins[pair.split('/')[1]].balance)) {
        setTotal(coins[pair.split('/')[1]].balance);
      }
    }
  }, [total]);
  
  
  useEffect(() => {
    console.log("recentOrders", recentOrders);
  }, [recentOrders]);
  
  
  const renderOrderbook = (obids,oasks) => {

    var lenbid = Math.min(25, Math.max(obids.length, oasks.length));
    return (
      new Array(lenbid).fill(0).map((_,index)=>{
        var bid = {amount:"", price: ""}
        var ask = {amount:"", price: ""}
        if (obids.length > index) {bid = obids[index]}
        if (oasks.length > index) {ask = oasks[index]}
        return (
          <tr>
              <td onClick={()=>{changeAmount(bid.amount)}} style={{cursor:"pointer"}}>{Number(bid.amount).toFixed(coins[pair.split('/')[0]].decimals)}</td>
              <td className={classes.green} onClick={()=>{changePrice(bid.price)}} style={{cursor:"pointer"}}>{bid.price}</td>
              <td className={classes.red} onClick={()=>{changePrice(ask.price)}} style={{cursor:"pointer"}}>{ask.price}</td>
              <td onClick={()=>{changeAmount(ask.amount)}} style={{cursor:"pointer"}}>{Number(ask.amount).toFixed(coins[pair.split('/')[0]].decimals)}</td>
          </tr>
          )
     }));
  };
  const renderBid = (obids) => {
    var totalam = 0;
    var totalw = 0;
    const lambda = <Fragment>&nbsp;</Fragment>;
    var lenbid = obids.length>0 ? obids[0].amount*obids[0].price * obids.length : 1;
    var nobids = obids.slice()
    var newbids = nobids.reverse().map((bid, index) => {
      totalam += bid.amount*bid.price;
      return{
        amount: bid.amount,
        price: bid.price,
        lengt: totalam,
        index: index,
      }
    })

    return (
     newbids.reverse().map((bid) => {
      totalw = ((bid.lengt * 100) / lenbid).toFixed(0) + "%";
      var otstup = new Array(Math.floor(Math.sqrt(bid.index))).fill(0).map(()=>{return lambda});

      return (
        <tr><td className={classes.relpoint}>
            {bid.amount}
            
            </td>
            <td style={{color:'#E86037'}}>{bid.price}</td>
            <td className={classes.relpoint}>-</td>
        </tr>
        )
     }));
  };

  const renderAsk = (obids) => {
    var totalam = 0;
    var totalw = 0;
    const lambda = <Fragment>&nbsp;</Fragment>;
    var lenbid = obids.length>0 ? obids[0].amount*obids[0].price * obids.length : 1;
    return (
     obids.map((bid, index) => {
      totalam += bid.amount*bid.price;
      totalw = ((totalam * 100) / lenbid).toFixed(0) + "%";
      var otstup = new Array(Math.floor(Math.sqrt(index))).fill(0).map(()=>{return lambda});
      return (
        <tr><td className={classes.relpoint}>
            {bid.amount}
            
            </td>
            <td style={{color:'#37EC40'}}>{bid.price}</td>
            <td className={classes.relpoint}>-</td>
        </tr>
        )
     }));
  };
  
  const renderOrders = (orders) => {

    return (
      orders.map((item)=>{
        var krside  = "gr"
        var side = "buy"
        if (item.side==1) {
          side = "sell"
          krside  = "rd"
        }
        var krtype = "m"
        if (item.type==1) {
         krtype = "l"
        }
        let img = "/img/"+krtype+krside+".png"
        return (
              <tr>
                  <td>{new Date( Number(item.ctime) *  1000).toLocaleTimeString("en-US")}</td>
                  <td>{pair.split('/')[0]}/{pair.split('/')[1]}</td>
                  <td>{side}<img src={img} width="16" height="16" align="top" /> </td>
                  <td>{item.price}</td>
                  <td>{item.price}</td>
                  <td>{item.amount}</td>
                  <td>{Number(item.amount) - Number(item.left)}</td>
                  <td>{(Number(item.amount) * Number(item.price)).toFixed(coins[pair.split('/')[1]].decimals)} {pair.split('/')[1]}</td>
                  <td>Open</td>   
              </tr>
          )
     }));
  };
  const renderBalances = (coins) => {
        return (
          <>
            <tr>
              <td className={classes.txth17}>{coins["BTC"].name}</td>
              <td className={classes.txth17}>{Number(coins["BTC"].balance).toFixed(8)}</td>
            </tr>
            <tr>
              <td className={classes.txth17}>{coins["ETH"].name}</td>
              <td className={classes.txth17}>{Number(coins["ETH"].balance).toFixed(8)}</td>
            </tr>
            <tr>
              <td className={classes.txth17}>{coins["ZAR"].name}</td>
              <td className={classes.txth17}>{Number(coins["ZAR"].balance).toFixed(2)}</td>
            </tr>
          </>
          )
  };
  
  const renderTrades = (trades) => {

    return (
      trades.map((item)=>{
        
        return (
            <tr className={classes.trdata}><td onClick={()=>{changeAmount(item.amount)}} style={{cursor:"pointer"}}>{Number(item.amount).toFixed(coins[pair.split('/')[0]].decimals)}</td><td className={ item.type == "buy" ? classes.green : classes.red }  onClick={()=>{changePrice(Number(item.price)>1 ? Math.floor(item.price) : Number(item.price))}} style={{cursor:"pointer"}}>{Number(item.price)>1 ? Math.floor(item.price) : Number(item.price)}</td><td>{ new Date( Number(item.time) *  1000).toLocaleTimeString("en-US") }</td></tr>
          )
     }));
  };
  
  const renderNews = (crnews) => {
    const slicedArray = crnews.slice(0, 3);
    return (
      slicedArray.map((item)=>{
        
        return (
            <p className={classes.txt}><a target="_blank" className={classes.txt} href={item.url}>{item.texts}</a></p>
          )
     }));
  };  
  return (
    <div className={classes.root}  style={{overflow: "hidden"}}>
      <div className={classes.flex_vertical}>
        <div className={classes.leftyblock}>
          <div className={classes.tdvn}>
            <span className={classes.titleBa}>Wallet Balance</span>
            <div className={classes.top}>
              <table className={classes.w100}>
                <tr className={classes.tableorderstitle}>
                    <th className={classes.txth17}>Asset</th>
                    <th className={classes.txth17}>Amount</th>
                </tr>
                { renderBalances(coins) }

              </table>
            </div>
            <div className={classes.flex_g}>
              <div className={classes.tabbtn  } onClick={() => {}} >Deposit</div>
              <div className={classes.tabbtn  } onClick={() => {}} >Withdraw</div>
            </div>
            
          </div>

        
          <div className={classes.buy_sell}>
          <span className={classes.titleBa}>Order Form</span>
            <div className={classes.flex_g}>
              <div className={ activeTab === "buy" ? classes.tabactive : classes.tab } onClick={() => setActiveTab("buy")} >Buy</div>
              <div className={ activeTab === "sell" ? classes.tabactive : classes.tab } onClick={() => setActiveTab("sell")} >Sell</div>
            </div>
            <div className={classes.flex_g_tab}>
              <div className={ orderType === "market" ? classes.activetitle : classes.grey2title } onClick={() => setOrderType("market")}>Market</div>
              <div className={ orderType === "limit" ? classes.activetitle : classes.grey2title } onClick={() => setOrderType("limit")}>Limit</div>
              <div className={ orderType === "stop" ? classes.activetitle : classes.grey2title } onClick={() => setOrderType("stop")}>Stop</div>
            </div>
            <div className={classes.flex_g}>
              <div className={classes.inputtitle}>Amount</div>
              <div className={classes.inputtitle}></div>
            </div>
            <div className={classes.flex_forinput}>
              <div className={classes.flex_g_input}>
                <div className={classes.greyclicktitle} onClick={() => setAmount(coins[pair.split('/')[0]].balance)}>Max</div>
                <div className={classes.greytitle}> {pair.split('/')[0]}</div>
              </div>
              <input type="text" placeholder="" name="amount" className={classes.input} onChange={handleAmount} value={amount}/>
            </div>
            <div className={classes.flex_g}>
              <div className={classes.inputtitle}>Price</div>
              <div className={classes.inputtitle}></div>
            </div>
            <div className={classes.flex_forinput}>
              <div className={classes.flex_g_input}>
                <div className={classes.greytitle}></div>
                <div className={classes.greytitle} >{pair.split('/')[1]}</div>
              </div>
              <input type="text" placeholder="" name="price" className={classes.input} onChange={handlePrice} disabled={disableprice} value={price}/>
            </div>

            <div className={classes.flex_g}>
              <div className={classes.inputtitle}>Total</div>
              <div className={classes.inputtitle}></div>
            </div>
            <div className={classes.flex_forinput}>
              <div className={classes.flex_g_input}>
                <div className={classes.greyclicktitle} onClick={() => setTotal(coins[pair.split('/')[1]].balance)} >Max</div>
                <div className={classes.greytitle} >{pair.split('/')[1]}</div>
              </div>
              <input type="text" placeholder="" name="total" className={classes.input} onChange={handleTotal} value={total}/>
            </div>
            <div className={classes.flex_g}>
              
              <div className={classes.flex_v}>
                <div className={classes.inputtitle}>Maker 0.025%</div>
                <div className={classes.inputtitle}>Taker  0.075%</div>
              
              </div>
            </div>

            <div className={classes.pbutton} onClick={() => placeNewOrder(pair, amount, activeTab, orderType, price, total)} >{ activeTab === "sell" ? "PLACE SELL ORDER" : "PLACE BUY ORDER" }</div>
          </div>        
          
          
          <div className={classes.tdvn} style={{overflow: "hidden"}}>
            <span className={classes.titleBa}>Announcements</span>
            { renderNews(news) }
          </div>
        </div>
        
        <div className={classes.rootorderbook}   >

          <span className={classes.titleBa}>Order Book</span>
          <div className={classes.orderbook}>
            <div className={classes.top}>
              <table className={classes.w100}>
                { renderAsk(asks) }
              </table>
            </div>
            
            
            <div >
              <div  className={classes.center}>

                <span className={classes.f14}>Spread:</span>
                <br />
                <span className={classes.f14}></span>
              </div>
            </div>
            
            
            <div className={classes.down}>
            <table className={classes.w100}>
              
              { renderBid(bids) }
            </table>
            </div>
          </div>

        </div>      
        <div className={classes.flex_vB}>
          <span className={classes.titleBa}>Price Chart</span>
          <div className={classes.tdv} id="tvchartcontainer" ></div>
          <div className={classes.tdvnv166}>
              <span className={classes.titleBa}>Orders</span>
              <table className={classes.tableorders}>
                <tr className={classes.tableorderstitle}>
                    <th>Date</th>
                    <th>Pair</th>
                    <th>Side</th>
                    <th>Order Price</th>
                    <th>AVG. Price</th>
                    <th>Filled Amount</th>
                    <th>Order Amount</th>
                    <th>Order Total</th>
                    <th>Status</th>
                </tr>
                { renderOrders(openOrders) }
                </table>
          </div>
          
          
        </div>

        
        
        <div className={classes.rtdvn} >
          <span className={classes.titleBa}>Trade History</span>
          <table className={classes.trdata}>
            <tr><th className={classes.thtitle}>Size ({pair.split('/')[0]})</th><th className={classes.thtitle}>Price ({pair.split('/')[1]})</th><th className={classes.thtitle}>Time</th></tr>
            { renderTrades(lastDeals) }

          </table>
        </div>
        
        
      </div>
    </div>
  )
}

export default Trade;
