import { InputAdornment, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import { setStore } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "77px",
      minHeight: "calc(100% - 90px)",
      justifyContent: "space-between",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        textAlign: "center",
      }
    }
  },
  referral: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "24px",
  },
  referral_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  ref_root: {
    padding: "10px",
    width: "329px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
    textAlign: "center",
    border: "1px solid rgba(196, 196, 196, 0.3)",
    "@media (max-width: 767.98px)": {
      width: "100%",
    }
  },
  btc_root: {
    padding: "10px",
    width: "629px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
    textAlign: "center",
    border: "1px solid rgba(196, 196, 196, 0.3)",
    "@media (max-width: 767.98px)": {
      width: "100%",
    }
  },
  feature_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "195px",
    width: "765px",
    marginLeft: "39px",
    marginTop: "30px",
  },
  feature_item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "200px",
  },
  feature_txt: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
    textAlign: "center",
    marginTop: "50px",
  },
  invite: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "30px",
  },
  invite_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  email_root: {
    marginTop: "10px",
    width: "929px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
  },
  email_input: {
    color: "#67748E!important",
  },
  link: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "30px",
  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  textes: {
    fontWeight: "800",
    fontSize: "14px",
    textAlign: "center",
  },
  link_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "929px",
    marginTop: "10px",
  },
  link_root: {
    width: "741px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
  },
  link_input: {
    color: "#67748E!important",
  },
  link_button: {
    color: "#2698D4",
    textDecoration: "none",
    cursor: "pointer",
  }
}));

const Help = (props) => {
  const { darkTheme, refcode, btcaddress } = useSelector(getData);
  const classes = useStyles(darkTheme);
  
  const renderRefcode = (refcode) => {
    if (refcode) {
      return (
        <p className={classes.ref_root}>Ref: {refcode} </p>
      )
    } else {
      return (
        <div className={classes.ref_root} onClick={verify} >
          Get Code
        </div>
      )      
    }
  };
  
  
  const renderBTCcode = (btcaddress) => {
    if (btcaddress) {
      return (
        <p className={classes.btc_root} onClick={gobtc}>{btcaddress} </p>
      )
    } else {
      return (
        <div className={classes.btc_root} onClick={gobtc} >
          Add Bitcoin address
        </div>
      )      
    }
  };
  
  
  const verify = () => {
    setStore({ 
      activeTab: 'verify',
    });
  };
  
  
  const gobtc = () => {
    setStore({ 
      activeTab: 'addbtc',
    });
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.referral}>How to:</h2>
      <p className={classes.referral_description}>You can now buy Bitcoin at any Capitec atm using your refrence code. </p>
{ renderRefcode(refcode) }
      
      
      <p className={classes.invite_description}>Your Bitcoin will be sent to:</p>
{ renderBTCcode(btcaddress) }

      <p className={classes.link_description}>Use this refrence code at a Capitec ATM to deposit cash. Note open 9AM-4PM on weekdays.</p>
      <div className={classes.textes}>
        <p className={classes.textes}>Account details:</p>
        <p className={classes.textes}>Ankerplatform Pty LTD</p>
        <p className={classes.textes}>1051052904</p>
      </div>

    </div>
  );
};

export default Help;
