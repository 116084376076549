import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { getData } from "../../store/appStoreSlice";
import Home from "./Home/Home";
import Help from "./Help/Help";

import { setTheme, setStore, exitloc } from "../../utils";
import { makeStyles } from '@mui/styles';
import { useFBAuth, useGoogleAuth } from "../../providers";
import { googleLogout } from '@react-oauth/google';



const MaterialUISwitch = styled(Switch)(({ darkmode }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: darkmode === false ? 'white' : '#2698D4',
        border: darkmode === false ? "1px solid #4F4F4F" : '#2698D4',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: darkmode === true ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: darkmode === false ? 'white' : '#2698D4',
    borderRadius: 20 / 2,
    border: darkmode === false ? "1px solid #4F4F4F" : '#2698D4',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      display: "flex",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
      }
    }
  },
  tab_bar: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#282C31" : "white",
      width: "78px",
      borderRadius: "20px 0 0 20px",
      border: darkTheme ? "1px solid #4D4D4D" : "1px solid #E2E6E8",
      borderLeft: "none",
      "@media (max-width: 767.98px)": {
        flexDirection: "row",
        display: "flex",
        width: "100%",
      }
    }
  },
  tab_logo: {
    marginTop: "31px",
    marginLeft: "14px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  tab_buttons: {
    marginTop: "96px",
    width: "100%",
    height: "180px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "row",
        height: "auto",
      }
  },
  tab_button: {
    width: "100%",
    height: "35px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  tab_content: {
    width: "calc(100% - 78px)",
    minHeight: "100vh",
      "@media (max-width: 767.98px)": {
        margin: "6px",
        width: "100%",
      }
  },
  tab_content_header: {
    marginLeft: "77px",
    marginRight: "54px",
    display: "flex",
    justifyContent: "space-between",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        width: "100%",
      }
  },
  active_tab: {
    backgroundImage: "url(/img/active_bar.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100%",
    textAlign: "center",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar_content: {
    display: "flex",
    alignItems: "center",
    cursor:"pointer",
  },
  avatar_text: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  theme_switch: {
    bottom: "30px",
    left: "20px",
    position: "absolute",
   
      "@media (max-width: 767.98px)": {
         position: "initial",
      }
  },
}));

const SettingsPage = (props) => {
  const { darkTheme, username, activeSettingsTab, signedIn, isSignedIn } = useSelector(getData);
  const classes = useStyles(darkTheme);

  const [{ loaded, currentUser, isLoggedIn }, login, logout] = useFBAuth();
  
  const handleThemeChange = (e) => {
    setTheme(!darkTheme);
  }
  const setSignOut = () => {
    exitloc();
    if (isSignedIn ) { 
      googleLogout();
    }
    if (isLoggedIn ) { 
      logout();
    }
    exitloc();
  }
  const changetab = (tab) => {
    if (activeSettingsTab === tab)
      return;

    setStore({ 
      activeSettingsTab: tab,
    });
  };

  const renderHome = () => (<Home/>);
  const renderHelp = () => (<Help/>);

  const renderTabButton = (tabName) => (
    <a 
      onClick={() => changetab(tabName)} 
      className={ activeSettingsTab === tabName ? classes.active_tab : classes.tab_button }
    >
      { 
        activeSettingsTab === tabName 
        ? <img src={`/img/${tabName}_active.png`} width="20" height="20"/>
        : <img src={`/img/${tabName}.png`} width="20" height="20"/> 
      }
    </a>
  );

  return (
    <div className={classes.root}>
      <div className={classes.tab_bar}>
        <img className={classes.tab_logo} src="/img/logo.png" width="50" height="50"/>
        <div className={classes.tab_buttons}>
          <Link to="/dashboard"><img src={`/img/home.png`} width="20" height="20"/></Link>
          <Link to="/setting"><img src={`/img/verify_active.png`} width="20" height="20"/></Link>
          <Link to="/wallet"><img src={`/img/wallet.png`} width="20" height="20"/></Link>
          <Link to="/trade"><img src={`/img/trade.png`} width="20" height="20"/></Link>
        </div>
        <FormControlLabel
          classes={{root: classes.theme_switch}}
          control={<MaterialUISwitch darkmode={darkTheme} onChange={handleThemeChange}/>}
          label=""
        />
      </div>
      <div className={classes.tab_content}>
        <div className={classes.tab_content_header}>
          <h2>Welcome, { username }</h2>
          <div className={classes.avatar_content} onClick={setSignOut}>
            <p className={classes.avatar_text}>Sign Out&nbsp;&nbsp;&nbsp;</p>
            { darkTheme
              ? <img src="/img/avatar.png" width="21" height="21"/>
              : <img src="/img/avatar_gray.png" width="21" height="21"/>
            }
          </div>
        </div>
        { activeSettingsTab === 'setting' && renderHome() }
        { activeSettingsTab === 'help' && renderHelp() }
      </div>
    </div>
  )
}

export default withRouter(SettingsPage);
