import { InputAdornment, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import { setStore, storeBTC } from "../../../utils";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "77px",
      minHeight: "calc(100% - 90px)",
      justifyContent: "space-between",
    }
  },
  referral: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "24px",
  },
  referral_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  ref_root: {
    padding: "10px",
    width: "329px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
    textAlign: "center",
    border: "1px solid rgba(196, 196, 196, 0.3)",
  },
  btc_root: {
    padding: "10px",
    width: "629px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
    textAlign: "center",
    border: "1px solid rgba(196, 196, 196, 0.3)",
  },
  feature_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "195px",
    width: "765px",
    marginLeft: "39px",
    marginTop: "30px",
  },
  feature_item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "200px",
  },
  feature_txt: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
    textAlign: "center",
    marginTop: "50px",
  },
  invite: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "30px",
  },
  invite_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },

  email_root:darkTheme => {
    return {
      padding: "10px",
      width: "600px",
      margin: "10px",
      background: "rgba(196, 196, 196, 0.1)",
      boxSizing: "border-box!important",
      borderRadius: "20px!important",
      color: darkTheme ? "white" : "black",
      fontFamily: "Montserrat",
    }
  },

  email_input: {
    color: "#67748E!important",
  },
  link: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "30px",
  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  textes: {
    fontWeight: "800",
    fontSize: "14px",
    textAlign: "center",
  },
  link_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "929px",
    marginTop: "10px",
  },
  link_root: {
    width: "741px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
  },
  link_input: {
    color: "#67748E!important",
  },
  link_button: {
    color: "#2698D4",
    textDecoration: "none",
    cursor: "pointer",
  }
}));

const Addbtc = (props) => {
  const { darkTheme, refcode, btcaddress, verified, email, token } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [btc, setBtc] = useState('');
  const [editing, setEditing] = useState(false);


  const handleBtc = (e) => {
    setBtc(e.target.value);
  }
    
  useEffect(() => {
    if (btcaddress) {
      setBtc(btcaddress);
    }
  }, [btcaddress]);

  
  const renderBTCcode = (btcaddress) => {
    if (btcaddress && !editing) {
      return (
        <>
          <p className={classes.btc_root}>{btcaddress} </p>
          <Button
            classes={{root: classes.start_button}}
            variant="contained" 
            color="primary"
            onClick={editbtc}
          >
            Edit
          </Button>
        </>
      )
    } else {
      return (
        <div className={classes.btc_root}>
          <input id="phone-input" type="text" name="btc" className={classes.email_root} onChange={handleBtc} value={btc}/>
          <Button
            classes={{root: classes.start_button}}
            variant="contained" 
            color="primary"
            onClick={savebtc}
          >
            Save
          </Button>
          
        </div>
      )      
    }
  };
  
  
  const savebtc = () => {
    storeBTC(btc, email,token)
    setEditing(false);
    setStore({ 
      activeTab: 'help',
    });
  };
 
  const editbtc = () => {
    setEditing(true);
  };
 
  const verify = () => {
    setStore({ 
      activeTab: 'verify',
    });
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.referral}>BTC address white list:</h2>
      <p className={classes.referral_description}>Add your BTC address. </p>

{ renderBTCcode(btcaddress) }

      <div className={classes.textes}>
        <p className={classes.textes}>Account details:</p>
        <p className={classes.textes}>Ankerplatform Pty LTD</p>
        <p className={classes.textes}>1051052904</p>
      </div>

    </div>
  );
};

export default Addbtc;
