import React, { useEffect, useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getData } from "../../store/appStoreSlice";
import { IconButton, InputAdornment, Input, TextField, Button } from "@mui/material";
import { MainButton, useShowPopup, WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import {Helmet} from "react-helmet";
import { getbtc, neworder, placeNewOrder } from "../../utils";
import Datafeed from './datafeed.js';


const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
      }
    }
  },

  title: {
    textAlign: "center",
  },
  greytitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
  },
  greyclicktitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
    cursor:"pointer",
  },
  flex_forinput: {
    position: "relative",
    padding: "0 14px",
  },
  flex_g_input:darkTheme => {
    return {
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "8px",
      
    }
  },
  flex_g_select:darkTheme => {
    return {
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      padding: "8px",
      marginLeft: "10px",
      width: "100%",
    }
  },
  flex_g: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 14px",
  },
  flex_g_from: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "0 14px",
    color: "black",
  },
  input: darkTheme => {
    return {
      background: "transparent",
      border: "none !important",
      color: darkTheme ? "white" : "black",
      position: "absolute",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      top: "0",
      right: "30px",
      margin: "6px 16px",
      textAlign: "right",
      '&:focus': {
        outline: "none",
      }
    }
  },
  redinput: darkTheme => {
    return {
      background: "transparent",
      border: "none !important",
      color:"red",
      position: "absolute",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      top: "0",
      right: "30px",
      margin: "6px 16px",
      textAlign: "right",
      '&:focus': {
        outline: "none",
      }
    }
  },
  tab_bar: darkTheme => {
    return {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
    }
  },
  tab_logo: {
    margin: "6px",
  },
  tab_logoss: {

  },
  blockbg: {
    background: "rgba(38, 152, 212, 0.2)",
    border: "1px solid #000000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    padding: "14px",
    margin: "0 14px",
  },
  tab_buttons: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "13px",
    margin: "14px",
  },
  btcinfo: {
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "12px",
    padding: "0 14px",
  },
  inputtitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "12px",
    padding: "14px 0 0 0",
    textAlign: "right",
  },
  ititle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "12px",
    padding: "10px 0 0 0",
    textAlign: "right",
  },  
  ivalue:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "12px",
    textAlign: "right",
    display: "flex",
    justifyContent: "end",
  },
  
  active_tab: {
    textAlign: "center",
    width: "82px",
    alignItems: "center",

    borderLeft: "none",
    borderBottom: "2px solid rgba(176, 203, 217, 1)",
    textDecoration: "none",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  tab_button: darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
      width: "82px",
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer",


      textDecoration: "none",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      
    }
  },
  grey2title:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "18px",

    width: "100%",
    textAlign: "center",
    cursor:"pointer",
  },

  activetitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "18px",
    borderBottom: "2px solid rgba(176, 203, 217, 1)",
    width: "100%",
    textAlign: "center",
  },
  
  tab: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "18px",
    textAlign: "center",
    padding: "8px 0",
    background: "rgba(176, 203, 217, 0.20)",
    border: "1px solid rgba(176, 203, 217, 0.5)",
    width: "95px",
    cursor:"pointer",
    marginTop: "24px",
  },
  tabactive: darkTheme => {
    return {
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "9px",
      lineHeight: "18px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      padding: "8px 0",
      background: "rgba(55, 236, 64, 0.60)",
      border: "1px solid rgba(176, 203, 217, 0.5)",
      marginTop: "24px",
      width: "95px",
    }
  },
  
  content: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      borderRadius: "30px 0 0 30px",
      minWidth: "45%",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
      }
    }
  },
  tdv: {
    width: "100%",
    minHeight: "540px",
    height: "calc(100vh - 62px)",
    "@media (max-width: 767.98px)": {
      minHeight: "80vh",
    }
  },
  pbutton:  darkTheme => {
    return {
      background: "rgba(55, 236, 64, 0.60)",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "14px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      cursor:"pointer",

      border: "1px solid rgba(176, 203, 217, 0.5)",
      padding: "8px 20px",
      margin: "14px",
    }
  },
}));

const PeachExchange = (props) => {
  const { darkTheme, provider, buyPrice, sellPrice, pair, coins } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [initDataUnsafe] = useInitData();
  const [user, setUser] = useState("Buy Crypto");
  const [btcinfo, setBtcinfo] = useState(<div className={classes.btcinfo}>Welcome to AnkerPay, Please open AnkerPay bot and press ENTER to start</div>);
  const [formtype, setFormtype] = useState(0);
  const [checkoutId, setCheckoutId] = useState('');
  const [userId, setUserId] = useState("");
  const [amount, setAmount] = useState('1000');
  const [withdrawamount, setWithdrawamount] = useState('0.01');
  const [coin, setCoin] = useState('BTC');
  const [minerfee, setMinerfee] = useState(100);
  const [coinamount, setCoinamount] = useState('0.0000');
  const [price, setPrice] = useState(0);
  const [reccuring, setReccuring] = useState('Yes');
  const [page, setPage] = useState('chart');
  const [btcamount, setBtcamount] = useState('0.0000');
  
  const [activeTab, setActiveTab] = useState("sell");
  const [procent, setProcent] = useState("75");
  const [orderType, setOrderType] = useState("market");
  const [infoprice, setInfoprice] = useState('');
  const [total, setTotal] = useState('');  
  const [disableprice, setDisableprice] = useState(false);
  
  
  const handleAmount = (e) => {
    setAmount(e.target.value);
    setCoinamount( ((Number(e.target.value)-Number(minerfee))/Number(price)).toFixed(8) )
  }
  const handlePrice = (e) => {
    setPrice(e.target.value);
    if (e.target.value && Number(e.target.value)>0) {
      if (amount && Number(amount)>0) {
        setTotal((Number(e.target.value)*Number(amount)));
      } else if (total && Number(total)>0) {
        setAmount((Number(total)/Number(e.target.value)));
      }
    }
  }
  const handleTotal = (e) => {
    setTotal(e.target.value);
    if (price && Number(price)>0) {
      setAmount((Number(e.target.value)/Number(price)));
    }
  }
  
  const handleWithdrawamount = (e) => {
    setWithdrawamount(e.target.value);
  } 
  
  const changeReccuring = () => {
    if (reccuring == 'Yes') {
      setReccuring('No')
    } else {
      setReccuring('Yes')
    }
    
  }
  
  useEffect(() => {
    if (activeTab=="sell") {
      if (price == '') { setPrice(sellPrice) }
      setInfoprice(sellPrice);
    } else {
      if (price == '') { setPrice(buyPrice) }
      setInfoprice(buyPrice);
    }

  }, [activeTab, buyPrice, sellPrice, orderType]);
  
    
  useEffect(() => {
    setPage('chart')

  }, []);

  
  const widgetOptions = {
    symbol: "BTC/ZAR",
    datafeed: Datafeed,
    interval: '1D',
    container_id: 'tvchartcontainer',
    library_path: '/charting_library/',
    locale: 'en',
    disabled_features: [
            'use_localstorage_for_settings',
            'header_symbol_search',
            'left_toolbar',
            ],
    enabled_features: [],
    fullscreen: false,
    autosize: true,
    theme: darkTheme ? 'Dark' : 'Light',
    timezone: "Africa/Johannesburg",
    custom_css_url: darkTheme ? '/css/customTv.css' : '/css/empty.css', 
    overrides: {
        "mainSeriesProperties.candleStyle.upColor": "#02C77A",
        "mainSeriesProperties.candleStyle.downColor": "#FF3B69",
        "mainSeriesProperties.candleStyle.borderColor": "#378658",
        "mainSeriesProperties.candleStyle.borderUpColor": "#26a69a",
        "mainSeriesProperties.candleStyle.borderDownColor": "#ef5350",
        "mainSeriesProperties.candleStyle.wickColor": "#737375",
        "mainSeriesProperties.candleStyle.wickUpColor": "#02C77A",
        "mainSeriesProperties.candleStyle.wickDownColor": "#FF3B69",
    },
  };
  
  
  useEffect(() => {
    const tvWidget = new window.TradingView.widget(widgetOptions);
  }, [darkTheme]);

  useEffect(() => {
    if (page == 'chart') {
      const tvWidget = new window.TradingView.widget(widgetOptions);
    }
  }, [page]);

  useEffect(() => {
    async function fetchData() {
      if (initDataUnsafe && initDataUnsafe.user) {
        setUser(initDataUnsafe.user.first_name);
        setUserId(initDataUnsafe.user.id);
        let userbtc = await getbtc(initDataUnsafe.user.id);

        if (userbtc) {
          if (userbtc.code == 200) {
            setBtcinfo(
              <>
                  <div className={classes.flex_g}>
                    <div className={classes.ititle}>Crypto</div>
                    <div className={classes.flex_g_select}><div className={classes.ivalue}>{userbtc.coin} <div className={classes.greytitle}> &nbsp; ▼</div></div></div>
                  </div>
                  
                  <div className={classes.flex_g}>
                    <div className={classes.inputtitle}>{userbtc.coin} Address</div>
                    <div className={classes.inputtitle}></div>
                  </div>
                  <div className={classes.flex_forinput}>
                    <div className={classes.flex_g_input}>
                      <div className={classes.greytitle}> </div>
                      <div className={classes.greytitle}> &nbsp; </div>
                    </div>
                    <input type="text" placeholder="" name="btcaddress" className={classes.input}  value={userbtc.address}/>
                  </div>
              </>
            )
            setFormtype(1)
            setMinerfee(userbtc.fee)
            setPrice(Number(userbtc.price))
            setCoinamount( ((Number(amount)-Number(userbtc.fee))/Number(userbtc.price)).toFixed(8) )
          } else if (userbtc.code == 118) {
            setBtcinfo(<div className={classes.btcinfo}>Please Add Your BTC address first</div>)
          } else if (userbtc.code == 83) {
            setBtcinfo(<div className={classes.btcinfo}>Welcome to AnkerPay, Please open AnkerPay bot and press ENTER to start</div>)
          } else {
            setBtcinfo(<div className={classes.btcinfo}>Welcome to AnkerPay, Please open AnkerPay bot and press ENTER to start</div>)
          }
        }
      }
    }
    fetchData();
  }, [initDataUnsafe]);

  useEffect(() => {
    if (checkoutId) {
      setFormtype(2);
    }
  }, [checkoutId]);
  
  
  const renderTabButton = (tabName, tabText) => (
    <a 
      onClick={() => setPage(tabName)} 
      className={ ( page === tabName || ( page == 'wallet' && ( tabName == 'withdraw' || tabName == 'deposit' ))) ? classes.active_tab : classes.tab_button }
    >
      { tabText }
    </a>
  );
  
  return (
    <div className={classes.root}>
      <div className={classes.tab_bar}>
        <div className={classes.tab_logoss}>
          <img className={classes.tab_logo} src="/img/logo.png" width="46" height="46"  />
        </div>
        
        <div className={classes.tab_buttons} >
          { renderTabButton('chart','Chart') }
        </div>
        <div className={classes.tab_buttons}>
          { renderTabButton('trade','Trade') }
        </div>
        <div className={classes.tab_buttons}>
          { renderTabButton('wallet','Wallet') }
        </div>
      </div>
      
      
      
      
      <WebAppProvider>
        { page === 'chart' && 
          <div className={classes.profit_graph}>
              <div className={classes.tdv} id="tvchartcontainer" ></div>
          </div>
        }
        { page === 'deposit' && 
          <div className={classes.blockbg}>
            {btcinfo}
            { formtype === 1 &&             
              <>
                <div className={classes.flex_g}>
                  <div className={classes.inputtitle}>Amount</div>
                  <div className={classes.inputtitle}></div>
                </div>
                <div className={classes.flex_forinput}>
                  <div className={classes.flex_g_input}>
                    <div className={classes.greytitle}></div>
                    <div className={classes.greytitle}>ZAR</div>
                  </div>
                  <input type="text" placeholder="" name="amount" className={classes.input} onChange={handleAmount} value={amount}/>
                </div>



                
                <div className={classes.flex_g}>
                  <div className={classes.inputtitle}>You will receive</div>
                  <div className={classes.inputtitle}>Valid for 30 seconds</div>
                </div>
                <div className={classes.flex_forinput}>
                  <div className={classes.flex_g_input}>
                    <div className={classes.greytitle}></div>
                    <div className={classes.greytitle}>{coin}</div>
                  </div>
                  <input type="text" placeholder="" name="coinamount" className={ Number(coinamount)>0 ? classes.input : classes.redinput} value={coinamount}/>
                </div>

                <div className={classes.flex_g}>
                  <div className={classes.inputtitle}>Monthly recurring payment?</div>
                  <div className={classes.inputtitle}></div>
                </div>
                <div className={classes.flex_forinput}  onClick={changeReccuring} >
                  <div className={classes.flex_g_input}>
                    <div className={classes.greytitle}></div>
                    <div className={classes.greytitle}> &nbsp; ▼</div>
                  </div>
                  <div className={classes.input}>{reccuring}</div>
                </div>
                
                <div className={classes.pbutton} onClick={async () => setCheckoutId(await neworder(userId,amount,reccuring))} >Buy Now</div>

                
              </>
            }
            { formtype === 2 && 
              <>
                <Helmet>
                  
                  <style type="text/css">
                  { darkTheme ? `
                      .wpwl-form { color: white; }
                      .wpwl-control { color: white; }

                  ` : `
                      .wpwl-form { color: black; }
                      .wpwl-control { color: black; }

                  ` }
                  </style>
                  <style type="text/css">
                    {`
                     .wpwl-button-pay {
                        width: 100%;
                        font-style: normal;
                        font-family: Montserrat;
                        font-weight: 700;
                        line-height: 14px;
                        background: rgba(55, 236, 64, 0.60);
                        font-size: 12px;
                        border-radius: 0;
                      }
                      .wpwl-form {
                        background: none;
                        font-family: Montserrat;
                        border: none;
                        box-shadow: none;
                        padding: 14px 0;
                      }
                      .wpwl-label {
                          padding: 14px 0 0 0;
                          font-size: 12px;
                          font-style: normal;
                          font-family: Montserrat;
                          font-weight: 700;
                          line-height: 12px;
                      }
                      .wpwl-label-brand {
                        padding: 10px 10px 0 0;
                        font-size: 12px;
                        font-style: normal;
                        text-align: right;
                        font-family: Montserrat;
                        font-weight: 700;
                        line-height: 12px;
                      }
                      .wpwl-brand {
                        margin: 0;
                      }
                      .wpwl-hint {
                        font-family: Montserrat;
                        font-weight: 700;
                        font-size: 12px;
                      }
                      .wpwl-wrapper-brand {padding-right: 0;}
                      .wpwl-control {
                        background: transparent;
                        background-color: rgba(176, 203, 217, 0.2);
                        border-radius: 0;
                        border: none !important;
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                      }
                      .wpwl-control option {
                        background: transparent;
                        background-color: #415965;
                        border-radius: 0;
                        color: white;
                        border: none !important;
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                      }
                    `}
                  </style>                  
                  <script src={"https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="+checkoutId} type="text/javascript" />
                </Helmet>
                <div className={classes.flex_g_from}>
                  <form action={"https://app.ankerpay.com/checkout-status/"+checkoutId} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
                </div>
              </>
            }
          
        </div>
      }
      { page === 'trade' && 
        
        <div className={classes.blockbg}>
          <span className={classes.titleBa}>Order Form</span>
            <div className={classes.flex_g}>
              <div className={ activeTab === "buy" ? classes.tabactive : classes.tab } onClick={() => setActiveTab("buy")} >Buy</div>
              <div className={ activeTab === "sell" ? classes.tabactive : classes.tab } onClick={() => setActiveTab("sell")} >Sell</div>
            </div>
            <div className={classes.flex_g_tab}>
              <div className={ orderType === "market" ? classes.activetitle : classes.grey2title } onClick={() => setOrderType("market")}>Market</div>
              <div className={ orderType === "limit" ? classes.activetitle : classes.grey2title } onClick={() => setOrderType("limit")}>Limit</div>
              <div className={ orderType === "stop" ? classes.activetitle : classes.grey2title } onClick={() => setOrderType("stop")}>Stop</div>
            </div>
            <div className={classes.flex_g}>
              <div className={classes.inputtitle}>Amount</div>
              <div className={classes.inputtitle}></div>
            </div>
            <div className={classes.flex_forinput}>
              <div className={classes.flex_g_input}>
                <div className={classes.greyclicktitle} onClick={() => setAmount(coins[pair.split('/')[0]].balance)}>Max</div>
                <div className={classes.greytitle}> {pair.split('/')[0]}</div>
              </div>
              <input type="text" placeholder="" name="amount" className={classes.input} onChange={handleAmount} value={amount}/>
            </div>
            <div className={classes.flex_g}>
              <div className={classes.inputtitle}>Price</div>
              <div className={classes.inputtitle}></div>
            </div>
            <div className={classes.flex_forinput}>
              <div className={classes.flex_g_input}>
                <div className={classes.greytitle}></div>
                <div className={classes.greytitle} >{pair.split('/')[1]}</div>
              </div>
              <input type="text" placeholder="" name="price" className={classes.input} onChange={handlePrice} disabled={disableprice} value={price}/>
            </div>

            <div className={classes.flex_g}>
              <div className={classes.inputtitle}>Total</div>
              <div className={classes.inputtitle}></div>
            </div>
            <div className={classes.flex_forinput}>
              <div className={classes.flex_g_input}>
                <div className={classes.greyclicktitle} onClick={() => setTotal(coins[pair.split('/')[1]].balance)} >Max</div>
                <div className={classes.greytitle} >{pair.split('/')[1]}</div>
              </div>
              <input type="text" placeholder="" name="total" className={classes.input} onChange={handleTotal} value={total}/>
            </div>
            <div className={classes.flex_g}>
              
              <div className={classes.flex_v}>
                <div className={classes.inputtitle}>Maker 0.025%</div>
                <div className={classes.inputtitle}>Taker  0.075%</div>
              
              </div>
            </div>

            <div className={classes.pbutton} onClick={() => placeNewOrder(pair, amount, activeTab, orderType, price, total)} >{ activeTab === "sell" ? "PLACE SELL ORDER" : "PLACE BUY ORDER" }</div>

        </div>
      }
      { page === 'withdraw' && 
        
        <div className={classes.blockbg}>
          {btcinfo}
          <div className={classes.flex_g}>
            <div className={classes.inputtitle}>Balance</div>
            <div className={classes.inputtitle}></div>
          </div>
          <div className={classes.flex_forinput}>
            <div className={classes.flex_g_input}>
              <div className={classes.greytitle}> </div>
              <div className={classes.greytitle}> {coin} </div>
            </div>
            <input type="text" placeholder="" name="btcamount" className={classes.input}  value={btcamount}/>
          </div>
          <div className={classes.flex_g}>
            <div className={classes.inputtitle}>Amount</div>
            <div className={classes.inputtitle}></div>
          </div>
          <div className={classes.flex_forinput}>
            <div className={classes.flex_g_input}>
              <div className={classes.greytitle}></div>
              <div className={classes.greytitle}>{coin}</div>
            </div>
            <input type="text" placeholder="" name="withdrawamount" className={classes.input} onChange={handleWithdrawamount} value={withdrawamount}/>
          </div>

          <div className={classes.flex_g}>
            <div className={classes.inputtitle}>Miner Fee</div>
            <div className={classes.inputtitle}></div>
          </div>
          <div className={classes.flex_forinput}>
            <div className={classes.flex_g_input}>
              <div className={classes.greytitle}></div>
              <div className={classes.greytitle}>ZAR</div>
            </div>
            <input type="text" placeholder="" name="minerfee" className={classes.input}  value={minerfee}/>
          </div>
          <div className={classes.pbutton} onClick={() => setPage('withdraw')} >Withdraw</div>
        </div>
      }      
      { page === 'wallet' && 
        
        <div className={classes.blockbg}>
          {btcinfo}
          <div className={classes.flex_g}>
            <div className={classes.inputtitle}>Balance</div>
            <div className={classes.inputtitle}></div>
          </div>
          <div className={classes.flex_forinput}>
            <div className={classes.flex_g_input}>
              <div className={classes.greytitle}> </div>
              <div className={classes.greytitle}> {coin} </div>
            </div>
            <input type="text" placeholder="" name="btcamount" className={classes.input}  value={btcamount}/>
          </div>
          <div className={classes.pbutton} onClick={() => setPage('deposit')} >Deposit</div>
          <div className={classes.pbutton} onClick={() => setPage('withdraw')} >Withdraw</div>
        </div>
      }
        
      </WebAppProvider>
    </div>
  )
}

export default withRouter(PeachExchange);
