import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getData } from "../../store/appStoreSlice";
import { IconButton, InputAdornment, Input, TextField, Button } from "@mui/material";
import { MainButton, useShowPopup, WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import {Helmet} from "react-helmet";
import { getbtc, neworder, getinvstat } from "../../utils";


const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
      }
    }
  },

  title: {
    textAlign: "center",
  },
  greytitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
  },
  greyclicktitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
    cursor:"pointer",
  },
  flex_forinput: {
    position: "relative",
    padding: "0 14px",
  },
  flex_g_input:darkTheme => {
    return {
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "10px",
      
    }
  },
  flex_g: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 14px",
  },
  input: darkTheme => {
    return {
      background: "transparent",
      border: "none !important",
      color: darkTheme ? "white" : "black",
      position: "absolute",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      top: "0",
      right: "30px",
      margin: "6px 16px",
      textAlign: "right",
      '&:focus': {
        outline: "none",
      }
    }
  },
  blockbg: {
    background: "rgba(38, 152, 212, 0.2)",
    border: "1px solid #000000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    padding: "14px",
    margin: "0 14px",
  },
  tab_bar: darkTheme => {
    return {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        justifyContent: "center",
    }
  },
  tab_logo: {
    margin: "4px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  tab_logoss: {

  },
  tab_buttons: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "13px",
    margin: "14px",
  },
  btcinfo: {
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "14px",
    padding: "14px",
    wordWrap: "break-word",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
  },
  inputtitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "12px",
    padding: "14px 0 0 0",
    textAlign: "right",
  },
  content: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      borderRadius: "30px 0 0 30px",
      minWidth: "45%",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
      }
    }
  },
  pbutton:  darkTheme => {
    return {
      background: "rgba(55, 236, 64, 0.60)",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "14px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      cursor:"pointer",

      border: "1px solid rgba(176, 203, 217, 0.5)",
      padding: "8px 20px",
      margin: "14px",
      display: "block",
    }
  },
}));

const PeachStatus = (props) => {
  const { darkTheme, provider } = useSelector(getData);
  const classes = useStyles(darkTheme);

  const [status, setStatus] = useState("new");
  const [invstat, setInvstat] = useState("");
  const [checkloop, setCheckloop] = useState(false);
  const [loopstatus, setLoopstatus] = useState({status:'new', time:'', coin:'BTC', address:'', amount:'', fee:'', });

  const navigate = useHistory();

  
  useEffect(() => {
    if (status == "new") {
      setInvstat(
        <>
          <div><img src="/img/wait.png" width="46" height="46" /></div>
          <div>Waiting for payment ...</div>
        </>
      )
    } else if (status == "failed") {
      setInvstat(
        <>
          <div><img src="/img/no.png" width="46" height="46" /></div>
          <div>Failed</div>
        </>
      )
      
    } else if (status == "expired") {
      setInvstat(
        <>
          <div><img src="/img/no.png" width="46" height="46" /></div>
          <div>Expired</div>
        </>
      )
      
    } else if (status == "done") {
      setInvstat(
        <>
          <div><img src="/img/yes.png" width="46" height="46" /></div>
          <div>Success!</div>
          <div>You have purchased R{loopstatus.amount} worth of Bitcoin!</div>
          <div>Your {loopstatus.coin} Will be sent to:</div>
          <div>{loopstatus.address}</div>
          <div>Transaction Completed</div>

        </>
      )
      
    }
    

  }, [status]);

  useEffect(() => {

      console.log(props.match.params.checkoutid)

  }, []);
  
  useEffect( () => {
    const intervalId = (status == "new") && setInterval(async () => {
        const tttt = await getinvstat(props.match.params.checkoutid);
        await setLoopstatus(tttt);
        await setStatus(tttt.status);
      }, 1000);
    return () => clearInterval(intervalId)
  }, [status]);  

  
  return (
    <div className={classes.root}>
      <div className={classes.tab_bar}>
        <div className={classes.tab_logoss}>
          <img className={classes.tab_logo} src="/img/logo.png" width="46" height="46"/>
        </div>
      </div>
      
      <WebAppProvider>
        <div className={classes.blockbg}>
          <div className={classes.btcinfo}>
           Welcome to AnkerPay
          </div>
          <div className={classes.btcinfo}>
            Invoice #{props.match.params.checkoutid}
            
          </div>
          
          <div className={classes.btcinfo}>
            {invstat}
            
          </div>          
          
          <div className={classes.btcinfo}>
            
            <a href="/checkout" className={classes.pbutton} >HOME</a>
          </div>
        </div>
      </WebAppProvider>
    </div>
  )
}

export default withRouter(PeachStatus);
