import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  darkTheme: true,
  signedIn: false,
  enabled2fa: false,
  code2fa: null,
  provider: 'local',
  username: '',
  email: '',
  ETHZAR:0,
  BTCZAR:0,
  legacyaddress: null,
  pair: 'BTC/ZAR',
  volume24h: 0,
  volume24hBTC: 0,
  sellPrice: 0,
  buyPrice: 0,
  lastTrade: 0,
  lastTradeCh: 0,
  change24: 0,
  low: 0,
  hight: 0,
  bids: [],
  asks: [],
  lastDeals: [],
  openOrders: [],
  recentOrders: [],
  withdrawalHistory: [],
  depositHistory: [],
  authHistory: [],
  refcode: null,
  havepass: null,
  mqttKey: null,
  showOTP: true,
  signedInToCore: false,
  btcprice: 'R000000',
  btcperc: '0%',
  activeCoin: 'BTC',
  depositdetails: 'bank',
  withdrawdetails: 'bank',

  coins:{
    'ZAR':{
      min:500,
      name:'South African Rand ZAR',
      address:'',
      balance:0,
      freeze:0,
      decimals:2,
    },
    'BTC':{
      min:0.001,
      name:'Bitcoin',
      address:null,
      balance:0,
      freeze:0,
      decimals:8,
    },
    'ETH':{
      min:0.01,
      name:'Ethereum',
      address:null,
      balance:0,
      freeze:0,
      decimals:8,
    },
    
  },
  activeTab: 'home',
  activeSettingsTab: 'setting',
  activeWalletTab: 'wallet',
  activeExTab: 'trade',
  verified:0,
  errlogin:'',
  btcaddress:null,
  isSignedIn:null,
  googleUser:null,
  ustxs:null,
  token:null,
  isReady:true,
};

export const appStoreSlice = createSlice({
  name: "appStore",
  initialState: initialState,
  reducers: {
    setData: (state, action) => ({ ...state, ...action.payload }),
  }
});

export const { setData } = appStoreSlice.actions;

export const getData = (state) => state.appStore;

export default appStoreSlice.reducer;

