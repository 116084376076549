import { makeStyles } from "@mui/styles";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import Deposit from "../Deposit/Deposit";
import Withdraw from "../Withdraw/Withdraw";
import {  setStore } from "../../../utils";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {

      color: darkTheme ? "white" : "black",
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }
  },
  top_container: darkTheme => {
    return {

      marginLeft: "52px",
      marginRight: "40px",
      display: "flex",
      justifyContent: "center",
      borderRadius: "12px",

      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "column",
      }
    }
  },
  profit: {

    textAlign: "center",
    width: "450px",
    margin: "24px",
    padding: "32px 24px",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "3px",
    background: "rgba(38, 152, 212, 0.2)",
    "@media (max-width: 767.98px)": {
      width: "100%",
      padding: "0",
    }
  },
  profit_title: {
    fontWeight: "bold",
    fontSize: "20px",
    margin: 0,
  },
  box_label: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: "left",
    width: "100%",
  },
  profit_value: {
    display: "flex",
    alignItems: "center",
  },
  ptxs: {
    fontWeight: "bold",
    fontSize: "20px",

  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "normal",
    
  },
  profit_graph: {

    marginTop: "30px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  percent_value: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  percent_month: darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
    }
  },
  statistic: {
    width: "100%",
    marginTop: "36px",
    minHeight: "586px",
    display: "flex",
    flexDirection: "column",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
        height: "auto",
      }
    
  },
  flex_g: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    background: "rgba(38, 152, 212, 0.1)",
    padding: "6px",
    margin: "24px",
    border: "1px solid rgba(176, 203, 217, 0.2)",
  },
  tab: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "18px",
    textAlign: "center",
    padding: "8px",
    cursor:"pointer",
  },
  tabactive: darkTheme => {
    return {
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      padding: "8px",
      borderBottom:"3px solid #B0CBD9",

    }

  },
  
  

  statistic_box: darkTheme => {
    return {
      display: "flex",
      justifyContent: "space-between",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },
  wselect: darkTheme => {
    return {
      color: darkTheme ? "white !important" : "black !important",
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      padding: "0",
      border: "none  !important",
      fontSize: "13px !important",
      fontStyle: "normal",
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      lineHeight: "16px !important",
      margin: "0",
      width: "100%",
      textAlign: "left",
     "& svg": {
        color: darkTheme ? "white !important" : "black !important",
      },
      "& ul": {
          color: darkTheme ? "white !important" : "black !important",
          width: "100%",
      },

      
    }
  },

  statistic_box_col: darkTheme => {
    return {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        height: "auto",
      }
    }
  },
  statistic_box_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
      "@media (max-width: 767.98px)": {

        height: "auto",
      }
  },
  statistic_box_title: darkTheme => {
    return {
      fontSize: "14px",
      margin: 0,
      color: darkTheme ? "white" : "#67748E",
    }
  },
  trdata: {
    textAlign: "left",
    fontFamily: 'Helvetica',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "17px",
    width: "100%",
  },
  coinicon: {
    verticalAlign: "middle",
    marginRight: "10px",
  },
  thtitle: {
    textAlign: "left",
    margin: "6px",
    fontFamily: 'HelveticaNeueCyr',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    opacity: "0.54",  
  },
  statistic_box_value: {
    fontSize: "20px",
    margin: 0,
  },
  statistic_box_sub_value: {
    fontSize: "14px",
    color: "#13C39C",
  },
  bottom_container: {
    marginLeft: "52px",
    marginRight: "40px",
    marginTop: "25px",
    background: "linear-gradient(90.67deg, #2698D4 0%, #252525 100%)",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bottom_title: {
    paddingLeft: "47px",
    fontWeight: "bold",
    fontSize: "47px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "14px",
      }
  },
  bottom_value: {
    paddingRight: "16px",
    fontWeight: "bold",
    fontSize: "40px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "22px",
      }
  },
  regbutton: {
    background: "#10191F",
    border: "2px solid #FFFFFF",
    borderRadius: "12px",
    width: "108px",

    lineHeight: "16px",
    textAlign: "center",
    cursor: "pointer",
    margin: "4px",
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',




    color: '#FFFFFF',


  },
  slect: {
    fontFamily: 'HelveticaNeueCyr',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "17px",
    padding: "18px",
    display: "flex",
    justifyContent: "space-between",
    cursor:"pointer",
    "&:hover": {
      background: "#01AAEC33",
    },
  },
  disslect: {
    fontFamily: 'HelveticaNeueCyr',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "17px",
    padding: "18px",
    display: "flex",
    justifyContent: "space-between",

  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  textes: {
    fontWeight: "800",
    fontSize: "14px",
    textAlign: "center",
  },
}));

const DepositAll = (props) => {
  const { darkTheme, activeCoin } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [depositstep, setDepositstep] = useState("crypto");
  const [fiat, setFiat] = useState("bank");
  const [showdeposit, setShowdeposit] = useState(false);

  const cryptoChange = (event) => {
    setStore({ 
      activeCoin: event.target.value,
      depositdetails: null,
    });
    setShowdeposit(true);
  };
    
  const fiatChange = (event) => {
    setFiat(event.target.value)
    if (event.target.value === "bank") {
      setStore({ 
        activeCoin: "ZAR",
        depositdetails: "bank",
      });
    } else if (event.target.value === "capitec") {
      setStore({ 
        activeCoin: "ZAR",
        depositdetails: "capitec",
      });
    } else if (event.target.value === "cash") {
      setStore({ 
        activeCoin: "ZAR",
        depositdetails: null,
      });
    } else if (event.target.value === "card") {
      setStore({ 
        activeCoin: "ZAR",
        depositdetails: null,
      });
    } else if (event.target.value === "moya") {
      setStore({ 
        activeCoin: "ZAR",
        depositdetails: null,
      });
    } else if (event.target.value === "ozov") {
      setStore({ 
        activeCoin: "ZAR",
        depositdetails: null,
      });
    }  
    setShowdeposit(true);  
  };

  const goDeposit = (tocoin) => {
    setStore({ 
      activeCoin: tocoin,
      depositdetails: null,
    });
    setShowdeposit(true);
  };
  

  const goDepositwithparam = (tocoin,detal) => {
    setStore({ 
      activeCoin: tocoin,
      depositdetails: detal,
    });
    setShowdeposit(true);
  };
  

  useEffect(() => {
    setShowdeposit(false);
    if (depositstep === "fiat" && activeCoin !== "ZAR" ) {
      setStore({ 
        activeCoin: "ZAR",
        depositdetails: 'bank',
      });
      setFiat('bank')
    }
    if (depositstep === "crypto" && activeCoin === "ZAR" ) {
      setStore({ 
        activeCoin: "BTC",
        depositdetails: null,
      });
    }
    
  }, [depositstep]);
  

  const renderCrypto = () => {
    return (
      <>
        <div className={classes.box_label}>
          Select the asset:
        </div>
        <Select
          value={activeCoin}
          onChange={cryptoChange}
          className={classes.wselect}
        >
          <MenuItem value="BTC"><div><img src="/img/btc.png" width='18' className={classes.coinicon} /> Bitcoin (BTC)</div></MenuItem>
          <MenuItem value="ETH"><div><img src="/img/eth.png" width='18' className={classes.coinicon} />Ethereum (ETH)</div></MenuItem>
        </Select>          

      </>
    ) 
  }


  const renderFiat = () => {
    return (
      <>
        <div className={classes.box_label}>
          Select deposit:
        </div>

        <Select
          value={fiat}
          onChange={fiatChange}
          className={classes.wselect}
        >
          <MenuItem value="bank">Bank Transfer </MenuItem>
          <MenuItem value="capitec">Capitec ATM </MenuItem>
          <MenuItem value="cash" disabled>Cash Deposit (Coming Soon)</MenuItem>
          <MenuItem value="card" disabled>Card Payment (Coming Soon)</MenuItem>
          <MenuItem value="ozov" disabled>Ozow (Coming Soon)</MenuItem>
          <MenuItem value="moya" disabled>Moya (Coming Soon)</MenuItem>
        </Select>          

    </>
    ) 
  }
  


  const renderDepoTabs = () => {
    return (
      <div className={classes.flex_g}>
        <div className={ depositstep === 'fiat' ? classes.tabactive : classes.tab } onClick={() => setDepositstep("fiat")} >FIAT</div>
        <div className={ depositstep === 'crypto' ? classes.tabactive : classes.tab } onClick={() => setDepositstep("crypto")} >Crypto</div>
      </div>
    ) 
  }

  
  const renderDeposit = () => {
    return (
      <div className={classes.top_container}>
        <Deposit/>
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
    { renderDepoTabs() }
      <div className={classes.top_container}>
        <div className={classes.profit}>
          <div className={classes.profit_graph}>
            { depositstep === 'crypto' && renderCrypto() }
            { depositstep === 'fiat' && renderFiat() }
          </div>
        </div>
      </div>
      {  renderDeposit() }

    </div>
  )
}

export default DepositAll;
