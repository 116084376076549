import React from 'react';
import { FacebookLoginButton, createSvgIcon } from "react-social-login-buttons";
import facebookSVG from "../../img/facebook.svg";
import { useFBAuth } from '../../providers';

const FBLogin = (props) => {
  const [, login] = useFBAuth();
  
  return (
    <FacebookLoginButton 
      onClick={login} 
      style={{
        backgroundColor: "transparent", 
        border: "1px solid #E5E5E5", 
        width: "200px", 
        height: "45px", 
        display: "inline-block",
        fontSize: "12px",
        
        margin: "auto",
      }} 
      activeStyle={{backgroundColor: "#454545"}}
      icon={createSvgIcon(facebookSVG)({})}
      iconFormat={(name) => `fa ${name}`}
      iconSize="30"
    >
      <span>Sign up with Facebook</span>
    </FacebookLoginButton>
  );
}

export default FBLogin;
