import { setData } from '../store/appStoreSlice';
import store from '../store';
import axios from "axios";
import { toast } from 'react-toastify';

export const setStore = (payload) => {
  store.dispatch(setData(payload));
};

const {OAuth2Client} = require('google-auth-library');

const getStore = () => {
  return store.getState().appStore;
};

var postId = 1;

export const cointoname = {
    "BTC": 'bitcoin',
    "ETH": 'ethereum',
}

export const setTheme = (darkTheme) => {
  setStore({ darkTheme });
  const bodyElement = document.getElementsByTagName("body")[0];
  if (darkTheme)
    bodyElement.style.backgroundColor = "#252525";
  else
    bodyElement.style.backgroundColor = "white";
}

export const refreshTokenSetup = async (token) => {
    const clientid = "303897812754-50r2qpavk6lbgpq5easeutdrkks6rnhi.apps.googleusercontent.com";
    const client = new OAuth2Client(
      clientid
    );
    const tokenInfo = await client.getTokenInfo(token.access_token);
    
    console.log(tokenInfo);
    
  setStore({ 
      username: tokenInfo.email,
      email: tokenInfo.email,
      isSignedIn: true,
    });

};

export const upload = (file, step, email, username, provider, onUploadProgress, token) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("step", step);
  formData.append("email", email);
  formData.append("name", username);
  formData.append("provider", provider);
  formData.append("token", token);

  return axios.post("https://app.ankerpay.com/api/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

export const setphone = async (step, email, username, provider, phone,token) => {
  let formData = new FormData();

  formData.append("phone", phone);
  formData.append("step", step);
  formData.append("email", email);
  formData.append("name", username);
  formData.append("provider", provider);
  formData.append("token", token);

  const response = await  axios.post("https://app.ankerpay.com/api/setphone", formData);
  if (response.data) {
    setStore({ 
      refcode: response.data.refcode,
      havepass: response.data.havepass,
    });
  };

};


export const topup = async (email, bank, amount) => {
  let formData = new FormData();

  formData.append("bank", bank);
  formData.append("amount", amount);
  formData.append("email", email);

  const response = await  axios.post("https://app.ankerpay.com/api/depo", formData);
  return response;

};

export const setdone = async (step, email, username, provider, token) => {
  let formData = new FormData();


  formData.append("step", step);
  formData.append("email", email);
  formData.append("name", username);
  formData.append("provider", provider);
  formData.append("token", token);

  const response = await  axios.post("https://app.ankerpay.com/api/setdone", formData);
  if (response.data) {
    setStore({ 
      refcode: response.data.refcode,
      havepass: response.data.havepass,
      verified: response.data.verified,
    });
  };

};

export const getPrice = async () => {
  const response = await fetch(`https://app.ankerpay.com/api/price`);
  const result = await response.json();
  setStore({ 
    btcprice: 'R' + result.data.bp,
    btcperc: result.data.change + '%',
  });
}


export const getNews = async () => {
  const response = await fetch(`https://app.ankerpay.com/api/getnews`);
  const result = await response.json();
  return result;
}

export const getUsTx = async (email, token) => {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("token", token);

  const response = await  axios.post("https://app.ankerpay.com/api/getustxs", formData);
  if (response.data) {
    setStore({ 
      ustxs: response.data.data,
    });
  }
  console.log(response);
}

export const loginIn = async (email, username, provider) => {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("name", username);
  formData.append("provider", provider);

  const response = await  axios.post("https://app.ankerpay.com/api/login", formData);
  if (response.data) {
    await  setStore({ 
      refcode: response.data.refcode,
      havepass: response.data.havepass,
      verified: response.data.verified,
      btcaddress: response.data.btcaddress,
      token: response.data.token,
    });
  }
  console.log(response);
}


export const storeBTC = async (btcaddress, email, token) => {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("btcaddress", btcaddress);
  formData.append("token", token);


  const response = await  axios.post("https://app.ankerpay.com/api/savebtc", formData);
  if (response.data) {
    setStore({ 
      refcode: response.data.refcode,
      havepass: response.data.havepass,
      verified: response.data.verified,
      btcaddress: response.data.btcaddress,
    });
  }
  console.log(response);
}

export const registerLoc = async (name, email, password, provider) => {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("name", name);
  formData.append("password", password);
  formData.append("provider", provider);
  
  const response = await  axios.post("https://app.ankerpay.com/api/register", formData);
  if (response.data) {
    if (response.data.done == 1) {
      setStore({ 
        refcode: response.data.refcode,
        havepass: response.data.havepass,
        verified: response.data.verified,
        username: name,
        email: email,
        signedIn: true,
        token: response.data.token,
      });
    } else {
      setStore({ 
        errlogin: '',
      });
    }
  }
  console.log(response);
  return response;
}
export const exitloc = async () => {
      setStore({ 
        signedIn: false,
        provider: 'local',
        username: '',
        email: '',
        refcode: null,
        havepass: null,
        btcaddress: null,
        isSignedIn: false,
        token: null,
      });
}

export const loginLoc = async (email, password, provider) => {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  formData.append("provider", provider);
  
  const response = await  axios.post("https://app.ankerpay.com/api/lologin", formData);
  if (response.data) {
    if (response.data.done == 1) {
      setStore({ 
        refcode: response.data.refcode,
        havepass: response.data.havepass,
        verified: response.data.verified,
        username: response.data.name,
        email: email,
        signedIn: true,
        btcaddress: response.data.btcaddress,
        token: response.data.token,
      });
    } else {
      
    }
  }
  console.log(response);
  return response;
}

const tocore = async (method, params) => {

    let formData = {
      'method': method,
      'id':postId,
      'params':params,
    };
    postId = postId+1;
    let responce = (await axios.post("/core/", formData)).data;
    if (responce.Expired && responce.Expired == 1) {
          setStore({ 
            signedInToCore: false,
          });
          toast.warn('Session Expired. Reconnecting...');
          const { email, token, username, provider } =  getStore();
          loginIn(email, username, provider)
          .then(logintocore(email, token, ""))
    }
    console.log(responce);
    return responce;
}

export const logintocore = async (email, token, otp) => {
    let params = [email, token, otp,];

    let response = await tocore('auth.login', params);
    if (response.Status == 1) {
        setStore({ 
          mqttKey: response.Userid,
          signedInToCore: false,
        });
        toast.info(response.Result);
        
      } else {
        if (response.Status == 100) {
          setStore({ 
            showOTP: true
          });
          toast.warn('Please enter 2FA ');
        } else {
          toast.error(response.Result);
        }
      }

    return response;
}

export const withdrawalTransactionHistoryData = async () => {
  const { mqttKey } =  getStore();
  let responce = await tocore('withdrawal.history', [mqttKey,]);
  setStore({ 
    withdrawalHistory: responce.data,
  });
}

export const depositTransactionHistoryData = async () => {
  const { mqttKey } =  getStore();
  let responce = await tocore('deposit.history', [mqttKey,]);
  setStore({ 
    depositHistory: responce.data,
  });
}

export const authhistoryData = async () => {
  const { mqttKey } =  getStore();
  let responce = await tocore('auth.history', [mqttKey,]);
  setStore({ 
    authHistory: responce,
  });
}


export const checkall = async () => {
  getRecentOrders();
  getOpenOrders();
  await getBalances();
  await getdepositaddress('BTC');
  await getdepositaddress('ETH');
}

export const getRecentOrders = async () => {
  const { mqttKey, pair } =  getStore();
  let symbol = pair.replace('/','');
  let responce = await tocore('market.user_deals', [mqttKey,symbol,0,50,]);
  setStore({ 
    recentOrders: responce.result.records,
  });
}


export const getOpenOrders = async () => {
  const { mqttKey, pair } =  getStore();
  let symbol = pair.replace('/','');
  let responce = await tocore('order.pending', [mqttKey,symbol,0,50,]);
  setStore({ 
    openOrders: responce.result.records,
  });
}


export const getBalances = async () => {
  const { mqttKey, coins } =  getStore();

  let responce = await tocore('balance.query', [mqttKey,]);
  var newcoins = JSON.parse(JSON.stringify(coins));
  newcoins['ZAR'].balance = responce.result.ZAR.available;
  newcoins['ZAR'].freeze = responce.result.ZAR.freeze;
  newcoins['BTC'].balance = responce.result.BTC.available;
  newcoins['BTC'].freeze = responce.result.BTC.freeze;
  newcoins['ETH'].balance = responce.result.ETH.available;
  newcoins['ETH'].freeze = responce.result.ETH.freeze;
  setStore({ 
    coins: newcoins,
    BTCZAR:responce.prices.BTCZAR.result,
    ETHZAR:responce.prices.ETHZAR.result,
  });
}


export const placeNewOrder = async (pair, amount, tabside, orderType, price, total) => {
  const { mqttKey } =  getStore();
  var side = 2
  var orderamount = String(total)
  if (tabside == 'sell') {
    side = 1
    orderamount = String(amount)
  }

  // buy = amount in btc, sell in zar
  let symbol = pair.replace('/','');
  var type = 'order.put_market';
  var params = [mqttKey,symbol,side,orderamount,'0.00','',]
  if (orderType == 'limit') {
    type = 'order.put_limit';
    params = [mqttKey,symbol,side,String(amount),String(price),'0.00','0.00','']
  }

  let responce = await tocore(type, params);
    if (responce.error) {
       toast.error(responce.error.message);
    } else {
      toast.success('New '+orderType+' order has been placed');
    }
  return responce;
}

export const cancelOrder = async (pair, orderId) => {
  const { mqttKey } =  getStore();

  let symbol = pair.replace('/','');
  let responce = await tocore('order.cancel', [mqttKey,symbol,orderId,]);
    if (responce.error) {
       toast.error(responce.error.message);
    } else {
      toast.success('Order has been cancelled');
    }
  return responce;
}

export const withdrawal = async (amount, recievingAddress, coin, txid, emailOtp) => {
  const { mqttKey } =  getStore();


  let responce = await tocore('withdrawal.confirm', [mqttKey,amount,recievingAddress,coin,txid,emailOtp,]);
    if (responce.err) {
       toast.error(responce.err);
    } else if(responce.Expired && responce.Expired == 1) {
       toast.error("Expired 2FA code");
    } else {
      toast.success('Withdrawal request has been sent.');
      checkall()
      .then(withdrawalTransactionHistoryData())
      
    }
  
  return responce;
}

const tocorewitharg = async (method, args) => {
    args['method'] = method;

    let responce = (await axios.post("/core/", args)).data;
    if (responce.Expired && responce.Expired == 1) {
          setStore({ 
            signedInToCore: false,
          });
          toast.warn('Session Expired. Reconnecting...');
          const { email, token, username, provider } =  getStore();
          loginIn(email, username, provider)
          .then(logintocore(email, token, ""))
    }
    console.log(responce);
    return responce;
}

export const getdepositaddress = async (activeCoin) => {
  const { mqttKey,coins } =  getStore();
  console.log(cointoname[activeCoin]);
  let responce = await tocorewitharg('user.deposit_address', {userid:mqttKey, currency:cointoname[activeCoin]});
  var newcoins = JSON.parse(JSON.stringify(coins));
  console.log(responce);
  newcoins[activeCoin].address = responce.Address;
  setStore({ 
    coins: newcoins,
  });
  if (activeCoin == "BTC") {
    responce = await tocorewitharg('user.deposit_address', {userid:mqttKey, currency:'legacy'});
    setStore({ 
      legacyaddress: responce.Address,
    });
  }
}

export const check2fa = async () => {
  const { email, token } =  getStore();

  let formData = new FormData();
  formData.append("email", email);
  formData.append("token", token);
  const response = await  axios.post("https://app.ankerpay.com/api/get2fa", formData);
  if (response.data) {
    setStore({ 
      code2fa: response.data.code2fa,
      enabled2fa: response.data.enabled2fa,
    });
  } else if (response.Expired && response.Expired == 1) {
          setStore({ 
            signedInToCore: false,
          });
          toast.warn('Session Expired. Reconnecting...');
          const { email, token, username, provider } =  getStore();
          loginIn(email, username, provider)
          .then(logintocore(email, token, ""))
    }
  return response;
}

export const save2fa = async (code2fa) => {
  const { email, token } =  getStore();

  let formData = new FormData();
  formData.append("email", email);
  formData.append("token", token);
  formData.append("code2fa", code2fa);
  const response = await  axios.post("https://app.ankerpay.com/api/get2fa", formData);
  if (response.data) {
    if (!response.data.err) {
      setStore({ 
        code2fa: response.data.code2fa,
        enabled2fa: response.data.enabled2fa,
      });
    } else {
      toast.error(response.data.err);
    }
  } else if (response.Expired && response.Expired == 1) {
          setStore({ 
            signedInToCore: false,
          });
          toast.warn('Session Expired. Reconnecting...');
          const { email, token, username, provider } =  getStore();
          loginIn(email, username, provider)
          .then(logintocore(email, token, ""))
  };
  return response;
}

export const getbtc = async (uid) => {

  let formData = new FormData();
  formData.append("uid", uid);

  const response = await  axios.post("https://app.ankerpay.com/api/getbtc", formData);
  if (response.data) {
    return response.data;
  } else {
    return false;
  };
  
}

export const getinvstat = async (uid) => {

  let formData = new FormData();
  formData.append("uid", uid);

  const response = await  axios.post("https://app.ankerpay.com/api/getinvstat", formData);
  if (response.data) {
    return response.data;
  } else {
    return {status:"new"};
  };
  
}
export const neworder = async (uid, amount, reccuring) => {

  let formData = new FormData();
  formData.append("uid", uid);
  formData.append("amount", amount);
  formData.append("reccuring", reccuring);

  const response = await  axios.post("https://app.ankerpay.com/api/getchid", formData);
  if (response.data && response.data.uid) {
    return response.data.uid.ndc;
  } else {
    return false;
  };
  
}
