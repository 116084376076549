import { makeStyles } from "@mui/styles";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import { useHistory } from "react-router-dom";
import { setStore, getUsTx } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      fontFamily: "HelveticaNeueCyr !important",
    }
  },
  top_container: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#10191F" : "white",
      marginLeft: "52px",
      marginRight: "40px",
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "12px",

      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "column",
      }
    }
  },
  profit: {
    width: "100%",
    height: "100%",
    padding: "32px 24px",
    
    "@media (max-width: 767.98px)": {

      padding: "0",
    }
  },
  profit_title: {
    fontWeight: "bold",
    fontSize: "18px",
    margin: 0,
  },
  profit_value: {
    display: "flex",
    alignItems: "center",
  },
  ptxs: {
    fontWeight: "bold",
    fontSize: "20px",

  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "normal",
    
  },
  profit_graph: {
    marginTop: "30px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  percent_value: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  percent_month: darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
    }
  },
  statistic: {
    width: "100%",
    marginTop: "36px",
    minHeight: "586px",
    display: "flex",
    flexDirection: "column",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
        height: "auto",
      }
    
  },
  statistic_box: darkTheme => {
    return {
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
      padding: "16px",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },

  statistic_box_col: darkTheme => {
    return {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        height: "auto",
      }
    }
  },
  statistic_box_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
      "@media (max-width: 767.98px)": {

        height: "auto",
      }
  },
  statistic_box_title: darkTheme => {
    return {
      fontSize: "14px",
      margin: 0,
      color: darkTheme ? "white" : "#67748E",
    }
  },
  trdata: {
    textAlign: "left",
    fontFamily: 'HelveticaNeueCyr',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "17px",
    width: "100%",
  },
  coinicon: {
    verticalAlign: "middle",
    marginRight: "10px",
  },
  thtitle: {
    textAlign: "left",
    margin: "6px",
    fontFamily: 'HelveticaNeueCyr',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    opacity: "0.54",  
  },
  statistic_box_value: {
    fontSize: "20px",
    margin: 0,
  },
  statistic_box_sub_value: {
    fontSize: "14px",
    color: "#13C39C",
  },
  bottom_container: {
    marginLeft: "52px",
    marginRight: "40px",
    marginTop: "25px",
    background: "linear-gradient(90.67deg, #2698D4 0%, #252525 100%)",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bottom_title: {
    paddingLeft: "47px",
    fontWeight: "bold",
    fontSize: "47px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "14px",
      }
  },
  bottom_value: {
    paddingRight: "16px",
    fontWeight: "bold",
    fontSize: "40px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "22px",
      }
  },
  regbutton: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#10191F" : "white",
      color: darkTheme ? "white" : "black",
      border: darkTheme ? "2px solid #FFFFFF" : "2px solid #000",
      borderRadius: "12px",
      width: "108px",

      lineHeight: "16px",
      textAlign: "center",
      cursor: "pointer",
      margin: "4px",
      fontFamily: 'HelveticaNeueCyr',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
    }
  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  textes: {
    fontWeight: "800",
    fontSize: "14px",
    textAlign: "center",
  },
}));

const Home = (props) => {
  const { darkTheme, refcode, btcperc, btcprice, btcaddress, email, ustxs, token, coins, BTCZAR, ETHZAR } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const labelColor = darkTheme ? 'white' : 'gray'
  const history = useHistory();

    
  const changetab = (tab, coin) => {
    setStore({ 
      activeWalletTab: tab,
      activeCoin: coin,
    });
  };
  
  const changezartab = (tab, coin, detal) => {
    setStore({ 
      activeWalletTab: tab,
      activeCoin: coin,
      depositdetails: detal,
    });
  };   
  
  const gotrade = (coin) => {
    setStore({ pair: coin });
    history.push("/trade");
  };

    

  

  
  return (
    <div className={classes.root}>
      <div className={classes.top_container}>
        <div className={classes.profit}>
          <p className={classes.profit_title}>Balances</p>

          
          <div className={classes.profit_graph}>
            <table className={classes.trdata}>
              <tr className={classes.thtitle}>
                <th>Coin</th>
                <th>Balance</th>
                <th>Available Balance</th>
                <th>Rand Value</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr className={classes.td}>
                <td><img src="/img/btc.png" width='18' className={classes.coinicon} />Bitcoin</td>
                <td>{(Number(coins["BTC"].balance) + Number(coins["BTC"].freeze)).toFixed(8) }</td>
                <td>{Number(coins["BTC"].balance).toFixed(8)}</td>
                <td>{(Number(coins["BTC"].balance)*(Number(BTCZAR))).toFixed(2)}</td>
                <td>
                  <div className={classes.regbutton} onClick={()=>{changetab("deposit", "BTC")}} >
                    Deposit
                  </div>
                </td>
                <td>                  
                  <div className={classes.regbutton} onClick={()=>{changetab("withdraw", "BTC")}} >
                    Withdraw
                  </div>
                </td>
                <td>                  
                  <div className={classes.regbutton} onClick={()=>{gotrade("BTC/ZAR")}} >
                    Trade
                  </div>
                </td>
              </tr>
              <tr className={classes.td}>
                <td><img src="/img/eth.png" width='18' className={classes.coinicon} />Ethereum</td>
                <td>{(Number(coins["ETH"].balance) + Number(coins["ETH"].freeze)).toFixed(8)}</td>
                <td>{Number(coins["ETH"].balance).toFixed(8)}</td>
                <td>{(Number(coins["ETH"].balance)*(Number(ETHZAR))).toFixed(2)}</td>
                <td>
                  <div className={classes.regbutton} onClick={()=>{changetab("deposit", "ETH")}} >
                    Deposit
                  </div>
                </td>
                <td>                  
                  <div className={classes.regbutton} onClick={()=>{changetab("withdraw", "ETH")}} >
                    Withdraw
                  </div>
                </td>
                <td>                  
                  <div className={classes.regbutton} onClick={()=>{gotrade("ETH/ZAR")}} >
                    Trade
                  </div>
                </td>
              </tr>
              <tr className={classes.td}>
                <td><img src="/img/zar.png" width='18' className={classes.coinicon} />South African Rand ZAR</td>
                <td>{(Number(coins["ZAR"].balance) + Number(coins["ZAR"].freeze)).toFixed(2)}</td>
                <td>{Number(coins["ZAR"].balance).toFixed(2)}</td>
                <td>{Number(coins["ZAR"].balance).toFixed(2)}</td>
                <td>
                  <div className={classes.regbutton} onClick={()=>{changezartab("deposit", "ZAR", "capitec")}} >
                    Deposit
                  </div>
                </td>
                <td>                  
                  <div className={classes.regbutton} onClick={()=>{changetab("withdraw", "ZAR")}} >
                    Withdraw
                  </div>
                </td>
                <td>                  
                  <div className={classes.regbutton} onClick={()=>{gotrade("BTC/ZAR")}} >
                    Trade
                  </div>
                </td>
              </tr>


            </table>

          </div>
        </div>

      </div>

    </div>
  )
}

export default Home;
