import { makeStyles } from "@mui/styles";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import { setStore, check2fa, save2fa, withdrawal } from "../../../utils";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { QRCode } from 'react-qrcode-logo';
import Verify from "../../DashboardPage/Verify/Verify";
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {

      color: darkTheme ? "white" : "black",
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }
  },
  top_container: darkTheme => {
    return {

      marginLeft: "52px",
      marginRight: "40px",
      display: "flex",
      justifyContent: "center",
      borderRadius: "12px",

      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "column",
      }
    }
  },
  profit: {

    textAlign: "center",
    width: "450px",
    margin: "24px",
    padding: "32px 24px",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "3px",
    background: "rgba(38, 152, 212, 0.2)",
    "@media (max-width: 767.98px)": {
      width: "100%",
      padding: "0",
    }
  },
  profit_title: {
    fontWeight: "bold",
    fontSize: "20px",
    margin: 0,
  },
  profit_value: {

  },
  ptxs: {
    fontWeight: "bold",
    fontSize: "20px",

  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "normal",
    
  },
  profit_graph: {
    marginTop: "30px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  percent_value: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  percent_month: darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
    }
  },
  thtitle: {
    textAlign: "left",
    margin: "6px",
    fontFamily: 'HelveticaNeueCyr',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    opacity: "0.54",  
  },
  trdata: {
    textAlign: "left",
    fontFamily: 'Helvetica',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "17px",
    width: "100%",
  },
  statistic: {
    width: "100%",
    marginTop: "36px",
    minHeight: "586px",
    display: "flex",
    flexDirection: "column",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
        height: "auto",
      }
    
  },
  statistic_box: darkTheme => {
    return {
      height: "50px",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      padding: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },
  txs_box: darkTheme => {
    return {
      justifyContent: "space-between",
      padding: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },
  wselect: darkTheme => {
    return {
      color: darkTheme ? "white !important" : "black !important",
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      padding: "0",
      fontSize: "13px !important",
      fontStyle: "normal",
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      lineHeight: "16px !important",
      margin: "0",
      width: "100%",
      border: "none  !important",
      textAlign: "left",
     "& svg": {
        color: darkTheme ? "white !important" : "black !important",
      },
      "& ul": {
          color: darkTheme ? "white !important" : "black !important",
          width: "100%",
      },

      
    }
  },
  box_label: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: "left",
    width: "100%",
  },
  box_text: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: "left",
  },
  box_input: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '16px',
    background: "rgba(176, 203, 217, 0.2)",
    padding: "12px",

  },

  statistic_box_col: darkTheme => {
    return {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        height: "auto",
      }
    }
  },
  statistic_box_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
      "@media (max-width: 767.98px)": {

        height: "auto",
      }
  },
  statistic_box_title: darkTheme => {
    return {
      fontSize: "14px",
      margin: 0,
      color: darkTheme ? "white" : "#67748E",
    }
  },
  statistic_box_value: {
    fontSize: "20px",
    margin: 0,
  },
  statistic_box_sub_value: {
    fontSize: "14px",
    color: "#13C39C",
  },
  bottom_container: {
    marginLeft: "52px",
    marginRight: "40px",
    marginTop: "25px",
    background: "linear-gradient(90.67deg, #2698D4 0%, #252525 100%)",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bottom_title: {
    paddingLeft: "47px",
    fontWeight: "bold",
    fontSize: "47px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "14px",
      }
  },
  bottom_value: {
    paddingRight: "16px",
    fontWeight: "bold",
    fontSize: "40px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "22px",
      }
  },
  regbutton: {
    background: "linear-gradient(135deg, #252525 0%, #2698D4 100%)",
    boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)",
    borderRadius: "8px",
    width: "108px",
    height: "42px",
    lineHeight: "42px",
    textAlign: "center",
    cursor: "pointer",
    margin: "4px",

  },
  start_button:  darkTheme => {
    return {
      background: "rgba(55, 236, 64, 0.60)  !important",
      fontFamily: 'Montserrat  !important',
      fontStyle: "normal  !important",
      fontWeight: "700  !important",
      fontSize: "12px  !important",
      lineHeight: "14px  !important",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      cursor:"pointer",
      width: "100%",
      border: "1px solid rgba(176, 203, 217, 0.5)  !important",
      padding: "14px 20px  !important",
      margin: "14px  !important",
    }
  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  textes: {
    fontWeight: "800",
    fontSize: "14px",
    textAlign: "center",
  },
}));

const Withdraw = (props) => {
  const { darkTheme, activeCoin, coins, enabled2fa, code2fa, token,mqttKey,verified, withdrawdetails } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [bank, setBank] = React.useState("ZA_MASTERPASS_CARD");
  const [amount, setAmount] = React.useState(coins[activeCoin].min);
  const [ret2fa, setRet2fa] = React.useState("");
  const [cryptoaddress, setCryptoaddress] = React.useState("");

    
  const changetab = (tab) => {
    setStore({ 
      activeWalletTab: tab,
    });
  };

  useEffect(() => {
    check2fa();
  }, []);
  

  const handleBank = (event) => {
    setBank(event.target.value);
  };

  const handleCryptoaddress = (event) => {
    setCryptoaddress(event.target.value);
  };
  
  const handleRet2fa = (event) => {
    if (!isNaN(event.target.value)) {
      setRet2fa(event.target.value);
    }
  };
  
  const handleAmount = (event) => {
    setAmount(event.target.value);
  };
  const handleSave2fa = async () => {
    save2fa(ret2fa);
  };
  
  const handleAction = async () => {
    if (Number(ret2fa) <1) {
      toast.error("Wrong 2FA code");
    } else if (Number(amount) > Number(coins[activeCoin].balance)) {
      toast.error("Insufficient balance");
    } else {
      if (activeCoin == 'ZAR') {
        withdrawal(amount,bank,activeCoin,token,ret2fa);
      } else {
        withdrawal(amount,cryptoaddress,activeCoin,token,ret2fa);
      }
    }
  }


  const renderQR = (enabled2fa, code2fa, activeCoin, verified) => {
    if (!verified) {
      return (
        <Verify/>
      )
    } else if (enabled2fa && activeCoin == 'ZAR') {
      if (withdrawdetails==="bank") {
        return (<>Bank</>)
      } 
      else 
      return (
        <div className={classes.statistic_box_col}>

            <div className={classes.box_label}>
              Select withdraw type:
            </div>
            <div className={classes.statistic_box}>
                <Select
                  value={bank}
                  onChange={handleBank}
                  className={classes.wselect}
                  label="Withdraw type"
                  // label="Bank"
                >
                  <MenuItem value="ZA_MASTERPASS_CARD">ZA_MASTERPASS_CARD</MenuItem>
                  <MenuItem value="ZA_OZOW">ZA_OZOW</MenuItem>
                  <MenuItem value="ZA_PEACH_CARD">ZA_PEACH_CARD</MenuItem>
                  <MenuItem value="ZA_PEACH_EFT">ZA_PEACH_EFT</MenuItem>
                  <MenuItem value="ZA_PNP_CASH">ZA_PNP_CASH</MenuItem>
                </Select>          
            </div>
            <div className={classes.box_label}>
              Amount:
            </div>
            <div className={classes.statistic_box}>
                <TextField
                  className={classes.wselect}
                  classes={{root: classes.wselect}}
                  value={amount}
                  onChange={handleAmount}
                  variant="outlined"
                  InputProps={{
                      classes: {
                          input: classes.wselect
                      }
                  }}
                />         
            </div>
            <div className={classes.statistic_box}>
                <TextField
                  label="2FA Code"
                  className={classes.wselect}
                  classes={{root: classes.wselect}}
                  value={ret2fa}
                  onChange={handleRet2fa}
                  variant="outlined"
                  InputProps={{
                      classes: {
                          input: classes.wselect
                      }
                  }}
                />         
            </div>
            <Button
              classes={{root: classes.start_button}}
              variant="contained" 
              color="primary"
              onClick={handleAction}
              
            >
              Withdraw
            </Button>

        </div>
      )
    } else if (enabled2fa) {
      return (
          <div className={classes.statistic_box_col}>
            {activeCoin} {coins[activeCoin].balance}
            <div className={classes.box_label}>
              Amount:
            </div>
            <div className={classes.statistic_box}>
                <TextField
                  className={classes.wselect}
                  classes={{root: classes.wselect}}
                  value={amount}
                  onChange={handleAmount}
                  variant="outlined"
                  InputProps={{
                      classes: {
                          input: classes.wselect
                      }
                  }}
                />         
            </div>
            <div className={classes.box_label}>
              {activeCoin} address:
            </div>
            <div className={classes.statistic_box}>
                <TextField
                  className={classes.wselect}
                  classes={{root: classes.wselect}}
                  value={cryptoaddress}
                  onChange={handleCryptoaddress}
                  variant="outlined"
                  InputProps={{
                      classes: {
                          input: classes.wselect
                      }
                  }}
                />         
            </div>
            <div className={classes.statistic_box}>
                <TextField
                  label="2FA Code"
                  className={classes.wselect}
                  classes={{root: classes.wselect}}
                  value={ret2fa}
                  onChange={handleRet2fa}
                  variant="outlined"
                  InputProps={{
                      classes: {
                          input: classes.wselect
                      }
                  }}
                />         
            </div>
            <Button
              classes={{root: classes.start_button}}
              variant="contained" 
              color="primary"
              onClick={handleAction}
              
            >
              Withdraw
            </Button>

          </div>
      )      
    } else if (code2fa) {
      return (
          <div className={classes.statistic_box_col}>
            Scan QR code 
            <QRCode value={ code2fa } size="240" logoImage="/img/logo.png"/>
            <div className={classes.statistic_box}>
                <TextField
                  label="2FA Code"
                  className={classes.wselect}
                  classes={{root: classes.wselect}}
                  value={ret2fa}
                  onChange={handleRet2fa}
                  variant="outlined"
                  InputProps={{
                      classes: {
                          input: classes.wselect
                      }
                  }}
                />         
            </div>
            <Button
              classes={{root: classes.start_button}}
              variant="contained" 
              color="primary"
              onClick={handleSave2fa}
              
            >
              Enable 2FA
            </Button>
          </div>
      )      
    } else {
      return (
          <div className={classes.statistic_box_col}>
            Loading ...
          </div>
      )      
    }
  };


  
  return (
    <div className={classes.root}>
      <div className={classes.top_container}>
        <div className={classes.profit}>
          <p className={classes.profit_title}>Wallet Withdraw</p>
          <div className={classes.profit_value}>
          { renderQR(enabled2fa, code2fa, activeCoin,verified) }
          </div>

        </div>
      </div>
    </div>
  )
}

export default Withdraw;
