import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import { Path } from "progressbar.js";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { upload,setphone,setStore,setdone } from "../../../utils";


const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }
  },
  title: {
    marginTop: "46px",
    fontWeight: "600",
    fontSize: "24px",
  },
  description: {
    fontWeight: "600",
    fontSize: "24px",
  },

  email_root:darkTheme => {
    return {
      padding: "10px",
      width: "329px",
      background: "rgba(196, 196, 196, 0.1)",
      boxSizing: "border-box!important",
      borderRadius: "20px!important",
      color: darkTheme ? "white" : "black",
    }
  },
  sub_description: {
    width: "355px",
    fontWeight: "600",
    fontSize: "14px",
    textAlign: "center",
    color: "#67748E",
  },
  sub_description_with_margin: {
    width: "355px",
    fontWeight: "600",
    fontSize: "14px",
    textAlign: "center",
    color: "#67748E",
    marginBottom: "121px",
  },
  verify_description_box: {
    width: "355px",
    display: "flex",
    alignItems: "center",
  },
  yes_no_box: {
    width: "171px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "82px",
    marginBottom: "76px",
  },
  verify_description: {
    marginLeft: "30px",
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  verify_description_1: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
    textAlign: "center",
    width: "100%",
  },
  start_button: {
    marginTop: "30px!important",
    height: "45px",
    width: "300px",
    backgroundColor: "#2698D4!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "#2698D4!important",
    }
  },
  progress_bar: {
    width: "168px",
    backgroundImage: "url(/img/upload.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  success_bar: {
    width: "168px",
    backgroundImage: "url(/img/success.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  uploading_bar: {
    width: "168px",
    backgroundImage: "url(/img/uploading.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  img_download: {
    marginTop: "46px",
    marginBottom: "20px",
    cursor: "pointer",
  },
  img_yes_no: {
    cursor: "pointer",
  },
  img_big_yes: {
    marginTop: "33px",
    marginBottom: "27px",
  },
  file_upload: {
    display: "none",
  },
  file_input: {
    cursor: "pointer",
  },
}));

const Verify = (props) => {
  var bar;
  const { darkTheme, email, username, provider, refcode, verified,token } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(0);
  const [next, setNext] = useState(false);
  const [uploading, setUploading] = useState(false);
  const ref = useRef();
  const data = [
    { // step 0
      description: "Verify your Identity",
      sub_description: "We need to check you are who you say you are. Here’s how it works",
    },
    { // step 1
      description: "Phone",
      sub_description: "Add your phone",
    },
    { // step 2
      description: "ID Selfie",
      sub_description: "Upload your ID Selfie with your ID and face clearly visible.",
    },
    { // step 3
      description: "Proof of Address",
      sub_description: "Upload your your proof of address not older than 3 months.",
    },
    { // step 4
      description: "Forex Partner Form",
      sub_description: "Please download complete and upload our Forex partner form.",
    },
    { // step 5
      description: "Source of Funds",
      sub_description: "Does the source of funds come from a directors loan?",
    },
    { // step 6
      description: "Bank Statement",
      sub_description: "Please upload a recent bank statement confirming available funds.",
    },
    { // step 7
      description: "Directors Loan",
      sub_description: "Please upload a copy of the directors loan agreement on a company letterhead. Click the download button for an example.",
    },
    { // step 8
      description: "Company Registration",
      sub_description: "Please upload a copy of your company registration form COR 14.3 issued by the CIPC.",
    },
    { // step 9
      description: "Success!",
      sub_description: "Your application is now under review. The review process take 1 working day.",
    },
  ];

  const renderProgressBar = () => {
    bar = new Path(ref.current, {
      easing: 'easeInOut',
      duration: 1400
    });

    bar.animate(0);
  }
  
  useEffect(() => {
    if (refcode) {
      setPhone(refcode,token);
    }
  }, [refcode]);
  
  useEffect(() => {
    if (verified>0) {
      setStep(9);
    }
  }, [verified]);
  
  const handleAction = () => {
    if (step == 3) {
      setStep(9);
    } else if (step == 1) {
      setphone(step, email, username, provider, phone)
      setStep(step + 1);
      setNext(false);
    } else if (step == 9) {
      gohelp();
      return;
    } else {
      setStep(step + 1);
      if (step != 8)
        setNext(false);
    }
  }
  
  const gohelp = () => {
    setdone(step, email, username, provider,token);
    setStore({ 
      activeTab: 'yesno',
    });
    
  };

  const onChangeProgress = (e) => {
    bar.animate(Math.round(e.loaded / e.total));
  }

  const handleUpload = async (e) => {
    setNext(false);
    setUploading(true);
    try {
      const response = await upload(e.target.files[0], step, email, username, provider, onChangeProgress,token);
      console.log(response);
    } catch (e) {
      console.log(e);
      setUploading(false);
      setNext(false);
    } finally {
      setUploading(false);
      setNext(true);
    }
  }

  const handleDownload = () => {
    alert("download");
  }

  const handlePhone = (e) => {
    setPhone(e.target.value);
    setNext(true);
  }

  const onYes = () => {
    setStep(step + 2);
    setNext(false);
  }

  const onNo = () => {
    handleAction();
  }

  useEffect(() => {
    if (step != 0 && step != 1 && step != 5 && step != 9 && !next)
      renderProgressBar();
    if (step == 1 && refcode)
      setNext(true);
  }, [step]);

  return (
    <div className={classes.root}>
      <p className={classes.title}>Documents</p>
      { step == 0
        ? <img src="/img/id.png" width="168" height="168"/>
        : step == 1
        ? <input id="phone-input" type="phone" name="phone" className={classes.email_root} onChange={handlePhone} value={phone}/>
        : step == 5
        ? <div className={classes.yes_no_box}>
            <img className={classes.img_yes_no} src="/img/yes.png" width="50" height="50" onClick={onYes}/>
            <img className={classes.img_yes_no} src="/img/no.png" width="50" height="50" onClick={onNo}/>
          </div>
        : step == 9
        ? <img className={classes.img_big_yes} src="/img/big_yes.png" width="100" height="100"/>
        : next == false
        ? <div className={ !uploading ? classes.progress_bar : classes.uploading_bar}>
            <label htmlFor="file-input" className={classes.file_input}>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 168 168">
                <path fillOpacity="0" strokeWidth="2" stroke="#2698D4" strokeOpacity="0.5" d="M 0 17 A 17 17 0 0 1 17 0 L 151 0 A 17 17 0 0 1 168 17 L 168 151 A 17 17 0 0 1 151 168 L 17 168 A 17 17 0 0 1 0 151 Z"/>
                <path ref={ref} id="progress-path" fillOpacity="0" strokeWidth="3" stroke="#2698D4" d="M 0 17 A 17 17 0 0 1 17 0 L 151 0 A 17 17 0 0 1 168 17 L 168 151 A 17 17 0 0 1 151 168 L 17 168 A 17 17 0 0 1 0 151 Z"/>
              </svg>
            </label>
            <input id="file-input" type="file" name="file" className={classes.file_upload} onChange={handleUpload} />
          </div>
        : <div className={classes.success_bar}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 168 168">
              <path fillOpacity="0" strokeWidth="2" stroke="#2698D4" d="M 0 17 A 17 17 0 0 1 17 0 L 151 0 A 17 17 0 0 1 168 17 L 168 151 A 17 17 0 0 1 151 168 L 17 168 A 17 17 0 0 1 0 151 Z"/>
            </svg>
          </div>
      }
      <p className={classes.description}>{data[step].description}</p>
      <p className={step == 2 || step == 5 || step == 6 || step == 9 ? classes.sub_description_with_margin : classes.sub_description}>{data[step].sub_description}</p>
      { step == 0 
        ? <div className={classes.verify_description_box}>
            <img src="img/avatar_gray.png" width="62" height="62"/>
            <p className={classes.verify_description}>Take a selfie or have someone<br/>assist in taking a picture of you<br/>holding up your ID or passport<br/>clearly vissable without<br/>obstructing your face.</p>
          </div>
        : step == 2
        ? <div className={classes.verify_description_box}>
            <p className={classes.verify_description_1}>Take a selfie or have someone<br/>assist in taking a picture of you<br/>holding up your ID or passport<br/>clearly vissable without<br/>obstructing your face.</p>
          </div>
        : step == 4 || step == 7 || step == 8
        ? <img className={classes.img_download} src={darkTheme ? "img/download.png" : "img/download_gray.png"} width="50" height="50" onClick={handleDownload}/>
        : <></>
        }
      <Button
        classes={{root: classes.start_button}}
        variant="contained" 
        color="primary"
        onClick={handleAction}
        disabled={!step || next ? false : true}
      >
        { step == 0 ? "Lets start" : "Continue" }
      </Button>
    </div>
  );
}

export default Verify;
