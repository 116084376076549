import { makeStyles } from "@mui/styles";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";

import { setStore, topup, getdepositaddress,withdrawalTransactionHistoryData,depositTransactionHistoryData } from "../../../utils";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import {
  useHistory
} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {

      color: darkTheme ? "white" : "black",
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }
  },
  top_container: darkTheme => {
    return {

      marginLeft: "52px",
      marginRight: "40px",
      display: "flex",
      justifyContent: "center",
      borderRadius: "12px",

      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "column",
      }
    }
  },
  profit: {

    textAlign: "center",
    width: "450px",
    margin: "24px",
    padding: "32px 24px",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "3px",
    background: "rgba(38, 152, 212, 0.2)",
    "@media (max-width: 767.98px)": {
      width: "100%",
      padding: "0",
    }
  },
  profit_title: {
    fontWeight: "bold",
    fontSize: "20px",
    margin: 0,
  },

  ptxs: {
    fontWeight: "bold",
    fontSize: "20px",

  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "normal",
    
  },
  profit_graph: {
    marginTop: "30px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  percent_value: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  percent_month: darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
    }
  },

  statistic_box: darkTheme => {
    return {
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
      padding: "16px",
      borderRadius: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },
  flex_g: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#01AAEC33",
    borderRadius: "12px 12px 0 0",
  },
  tab: {
    fontFamily: 'HelveticaNeueCyr',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
    textAlign: "center",
    padding: "8px",

    width: "50%",
    cursor:"pointer",
  },
  tabactive: darkTheme => {
    return {
      fontFamily: 'HelveticaNeueCyr',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "18px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      padding: "8px",

      backgroundColor: darkTheme ? "#10191F" : "white",
      borderRadius: "12px 12px 0 0",
      width: "50%",
    }

  },
  txs_box: darkTheme => {
    return {
      justifyContent: "space-between",
      padding: "16px",
      borderRadius: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },
  statistic_box_col: darkTheme => {
    return {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      borderRadius: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        height: "auto",
      }
    }
  },
  wselect: darkTheme => {
    return {
      color: darkTheme ? "white !important" : "black !important",
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      padding: "0",
      border: "none  !important",
      fontSize: "13px !important",
      fontStyle: "normal",
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      lineHeight: "16px !important",
      margin: "0",
      width: "100%",
      textAlign: "left",
     "& svg": {
        color: darkTheme ? "white !important" : "black !important",
      },
      "& ul": {
          color: darkTheme ? "white !important" : "black !important",
          width: "100%",
      },

      
    }
  },

  statistic_box_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

      "@media (max-width: 767.98px)": {

        height: "auto",
      }
  },
  statistic_box_title: darkTheme => {
    return {
      fontSize: "14px",
      margin: 0,
      color: darkTheme ? "white" : "#67748E",
    }
  },
  statistic_box_value: {
    fontSize: "20px",
    margin: 0,
  },
  statistic_box_sub_value: {
    fontSize: "14px",
    color: "#13C39C",
  },
  bottom_container: {
    marginLeft: "52px",
    marginRight: "40px",
    marginTop: "25px",
    background: "linear-gradient(90.67deg, #2698D4 0%, #252525 100%)",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },  
  qr: {
    borderRadius: "16px",
  },
  start_button:  darkTheme => {
    return {
      background: "rgba(55, 236, 64, 0.60)  !important",
      fontFamily: 'Montserrat  !important',
      fontStyle: "normal  !important",
      fontWeight: "700  !important",
      fontSize: "12px  !important",
      lineHeight: "14px  !important",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      cursor:"pointer",
      width: "100%",
      border: "1px solid rgba(176, 203, 217, 0.5)  !important",
      padding: "14px 20px  !important",
      margin: "14px  !important",
    }
  },
  bottom_title: {
    paddingLeft: "47px",
    fontWeight: "bold",
    fontSize: "47px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "14px",
      }
  },
  box_label: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: "left",
  },
  box_text: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: "left",
  },
  box_input: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '16px',
    background: "rgba(176, 203, 217, 0.2)",
    padding: "12px",

  },
  bottom_value: {
    paddingRight: "16px",
    fontWeight: "bold",
    fontSize: "40px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "22px",
      }
  },
  regbutton: {
    background: "linear-gradient(135deg, #252525 0%, #2698D4 100%)",
    boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)",
    borderRadius: "8px",
    width: "108px",
    height: "42px",
    lineHeight: "42px",
    textAlign: "center",
    cursor: "pointer",
    margin: "4px",

  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  textes: {
    fontWeight: "800",
    fontSize: "14px",
    textAlign: "center",
  },
}));

const Deposit = (props) => {
  const { darkTheme, refcode, btcprice, email, mqttKey, activeCoin, coins, depositHistory,legacyaddress,depositdetails } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [bank, setBank] = React.useState("ZA_MASTERPASS_CARD");
  const [amount, setAmount] = React.useState(500);
  const [estoken, setEstoken] = React.useState(null);
  const [segwit, setSegwit] = React.useState(true);
  const labelColor = darkTheme ? 'white' : 'gray'
  const history = useHistory();
  
  const handleChange1 = (event) => {
    setBank(event.target.value);
  };
  
  const segwitChange = (event) => {
    setSegwit(event.target.value);
  };
  
  const handleAmount = (event) => {
    setAmount(event.target.value);
  };
  useEffect(() => {
    if (mqttKey) {
      withdrawalTransactionHistoryData();
      depositTransactionHistoryData();
    }
  }, [mqttKey]);

  const handleAction = async () => {
    var topupres = await topup(email, bank, amount);
    console.log(topupres);
    if (topupres.data) {
      if (topupres.data.type == 'gourl') {
        window.location.href = topupres.data.result.completionUrl; 
      } else if (topupres.data.type == 'id') {
        setEstoken(topupres.data.result.topupId);
      } else {
        setEstoken(topupres.data.result.completionToken);
      }
    }
  }
    



  
  const renderQR = (activeCoin) => {
    if (activeCoin == 'ZAR' || !coins[activeCoin].address) {
      return (
        <>

        </>
      )
    } else if (activeCoin == 'BTC') {
      if (segwit) {
        return (
            <div className={classes.txs_box}>
              <img className={classes.qr} src={ "/api/qr?img=" + coins[activeCoin].address + "&amount=" + coins[activeCoin].min } width="240"/>
            </div>
        ) 
      } else if (legacyaddress) {
        return (
            <div className={classes.txs_box}>
              <img className={classes.qr} src={ "/api/qr?img=" + legacyaddress + "&amount=" + coins[activeCoin].min } width="240"/>
            </div>
        )         
      } else {
        return (
            <div className={classes.txs_box}>
             
            </div>
        ) 
      }
    } else {
      return (
          <div className={classes.txs_box}>
            <img className={classes.qr} src={ "/api/qr?img=" + coins[activeCoin].address + "&amount=" + coins[activeCoin].min } width="240"/>
          </div>
      )      
    }
  };

    
  const renderInfo = (activeCoin) => {
    if (estoken) {
        return (
            <div className={classes.profit_value}>
              <p className={classes.percent_value}>Your token for deposit:  <br /><b>{ estoken }</b></p>
            </div>
        )      
      
    } else   {
      if (activeCoin == 'ZAR') {
        if (depositdetails) {
          if (depositdetails == 'bank') {
            return (
              <>
                <div className={classes.statistic_box_content}>
                  <p className={classes.box_label}>Reference Code</p>
                  <p className={classes.box_input}>
                    EX{refcode}&nbsp;&nbsp;
                  </p>
                </div>
                <div className={classes.statistic_box_col}>
                    <p className={classes.link_description}>Use your refrence code at your bank account payment description to deposit money. </p>
                    <div className={classes.textes}>
                      <p className={classes.textes}>Account details:</p>
                      <p className={classes.textes}>Ankerplatform Pty LTD</p>
                      <p className={classes.textes}>1051052904</p>
                    </div>
                </div>
                <div className={classes.statistic_box_col}>
                    <p className={classes.link_description}>For assistance call 021 3001501.</p>
                    <div className={classes.textes}>
                      <p className={classes.textes}>Open Monday to Friday 9:00-17:00.</p>

                    </div>
                </div>         
              </>
            )
          } else  if (depositdetails == 'capitec') {
            return (
              <>
                <div className={classes.statistic_box_content}>
                  <p className={classes.box_label}>Reference Code</p>
                  <p className={classes.box_input}>
                    EX{refcode}&nbsp;&nbsp;
                  </p>
                </div>
                <div className={classes.statistic_box_col}>
                    <p className={classes.link_description}>Use your refrence code at a Capitec ATM to deposit cash. Note open 9AM-4PM on weekdays. Please keep your receipt.</p>
                    <div className={classes.textes}>
                      <p className={classes.textes}>Account details:</p>
                      <p className={classes.textes}>Ankerplatform Pty LTD</p>
                      <p className={classes.textes}>1051052904</p>
                    </div>
                </div>
                <div className={classes.statistic_box_col}>
                    <p className={classes.link_description}>For assistance call 021 3001501.</p>
                    <div className={classes.textes}>
                      <p className={classes.textes}>Open Monday to Friday 9:00-17:00.</p>

                    </div>
                </div>         
              </>
            )
          }

        } else {
        
        return (
          <>
            <div className={classes.box_label}>
              Select deposit type:
            </div>

            <Select
              value={bank}
              onChange={handleChange1}
              className={classes.wselect}
              
              label="Deposit type"
              // label="Bank"
            >
              <MenuItem value="ZA_MASTERPASS_CARD">ZA_MASTERPASS_CARD</MenuItem>
              <MenuItem value="ZA_OZOW">ZA_OZOW</MenuItem>
              <MenuItem value="ZA_PEACH_CARD">ZA_PEACH_CARD</MenuItem>
              <MenuItem value="ZA_PEACH_EFT">ZA_PEACH_EFT</MenuItem>
              <MenuItem value="ZA_PNP_CASH">ZA_PNP_CASH</MenuItem>
            </Select>          

            <div className={classes.box_label}>
              Amount:
            </div>

            <TextField
              label="Amount"
              className={classes.wselect}
              classes={{root: classes.wselect}}
              value={amount}
              onChange={handleAmount}
              variant="outlined"
              InputProps={{
                  classes: {
                      input: classes.wselect
                  }
              }}
            />         

            <Button
              classes={{root: classes.start_button}}
              variant="contained" 
              color="primary"
              onClick={handleAction}
              
            >
              Deposit
            </Button>
          </>
        )
        }
      } else if (!coins[activeCoin].address) {
        return (
            <div className={classes.profit_value}>
              <p className={classes.box_label}>{ activeCoin }&nbsp;&nbsp;Deposit address </p>
              <p className={classes.box_input}>Loading ...</p>
            </div>
        )      
      } else if (activeCoin=="BTC") {
        if (segwit) {
          return (
              <div>
                <p className={classes.box_label}>{ activeCoin }&nbsp;&nbsp;Deposit address </p>
                <p className={classes.box_input}>{ coins[activeCoin].address }</p>
                <p className={classes.box_label}>Note:</p>
                <p className={classes.box_text}>Send only BTC on the Bitcoin chain to this deposit address.</p>
                <p className={classes.box_text}>Sending any other coins or tokens except BTC to this address may result in a loss of your funds.</p>
              </div>
          )      
          
        } else if (legacyaddress) {
          return (
              <div>
                <p className={classes.box_label}>{ activeCoin }&nbsp;&nbsp;Deposit address </p>
                <p className={classes.box_input}>{ legacyaddress }</p>
                <p className={classes.box_label}>Note:</p>
                <p className={classes.box_text}>Send only BTC on the Bitcoin chain to this deposit address.</p>
                <p className={classes.box_text}>Sending any other coins or tokens except BTC to this address may result in a loss of your funds.</p>
              </div>
          ) 

        } else {
          return (
              <div>
                <p className={classes.box_label}>{ activeCoin }&nbsp;&nbsp;Deposit address </p>
                <p className={classes.box_input}>Loading ...</p>
              </div>
          )      
        }
      } else {
        return (
            <div className={classes.profit_value}>
                <p className={classes.box_label}>{ activeCoin }&nbsp;&nbsp;Deposit address </p>
                <p className={classes.box_input}>{ coins[activeCoin].address }</p>
                <p className={classes.box_label}>Note:</p>
                <p className={classes.box_text}>Send only { activeCoin } on the { activeCoin } chain to this deposit address.</p>
                <p className={classes.box_text}>Sending any other coins or tokens except { activeCoin } to this address may result in a loss of your funds.</p>
            </div>
        )      
      }
    }
  };
  
  const renderDeposits = (deposits) => {
    return (
      deposits.map((item)=>{
        return (
              <div className={classes.row}>
                <div className={classes.cell}>
                {item[4]}
                </div>
                <div className={classes.cell}>
                {item[2]}
                </div>
                <div className={classes.cell}>
                {item[5]}
                </div>
              </div>

          )
     }));
  };

  
  return (
    <div className={classes.root}>

      <div className={classes.top_container}>

        <div className={classes.profit}>
        
          
          <p className={classes.profit_title}>Wallet Deposit</p>
          <div>
            <p className={classes.percent_value}>{ activeCoin }&nbsp;&nbsp;<span className={classes.percent_month}>{coins[activeCoin].balance}</span></p>
          </div>
          
        {  activeCoin == 'BTC' && 
          
          
              <div>
                <p className={classes.box_label}>Bitcoin Address Type</p>

                

                <Select
                  value={segwit}
                  onChange={segwitChange}
                  className={classes.wselect}
                  

                  // label="Bank"
                >
                  <MenuItem value={true}>Segwit</MenuItem>
                  <MenuItem value={false}>Legacy</MenuItem>
                </Select>          
                
              </div>
          
          
        }

          { renderQR(activeCoin) }

          { renderInfo(activeCoin) }

        </div>
      </div>
    </div>
  )
}

export default Deposit;
