import React, { createContext, useContext } from 'react'
import { useFacebookLogin } from 'react-use-fb-login';
import { useGoogleLogin } from 'react-use-googlelogin';

import { refreshTokenSetup } from '../utils';

//const googleClientId = "641448362834-t89hli8btgftjsq47pefnm2pr0h4a28p.apps.googleusercontent.com";
const googleClientId = "303897812754-50r2qpavk6lbgpq5easeutdrkks6rnhi.apps.googleusercontent.com";
const FBappId = "809894609422974";
const GoogleAuthContext = createContext();
const FBAuthContext = createContext();

export const GoogleAuthProvider = ({ children }) => {
  const onSuccess = (res) => {
    console.log('Login ok: res:', res);
    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
  };

  const googleAuth = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: googleClientId,
    isSignedIn: true,
    accessType: 'offline',
  });

  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {children}
    </GoogleAuthContext.Provider>
  )
}

export const useGoogleAuth = () => useContext(GoogleAuthContext);

export const FBAuthProvider = ({ children }) => {
  const FBProps = {
    appId: FBappId,
    language: "EN",
    version: "2.5",
    fields: ["name","email"],
    onFailure: error => {
      console.log(error);
    }
  };

  const FBAuth = useFacebookLogin(
    FBProps
  );

  return (
    <FBAuthContext.Provider value={FBAuth}>
      {children}
    </FBAuthContext.Provider>
  )
}

export const useFBAuth = () => useContext(FBAuthContext);
