import { makeStyles } from "@mui/styles";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import Chart from "react-apexcharts";
import { setStore, getUsTx } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }
  },
  top_container: {
    marginLeft: "52px",
    marginRight: "40px",
    display: "flex",
    justifyContent: "space-between",

      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "column",
      }
  },
  profit: {
    width: "852px",
    minHeight: "558px",
    padding: "32px 24px",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "12px",
    "@media (max-width: 767.98px)": {
      width: "100%",
      padding: "0",
    }
  },
  profit_title: {
    fontWeight: "bold",
    fontSize: "20px",
    margin: 0,
  },
  profit_value: {
    display: "flex",
    alignItems: "center",
  },
  ptxs: {
    fontWeight: "bold",
    fontSize: "20px",

  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "normal",
    
  },
  profit_graph: {
    marginTop: "30px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  percent_value: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  percent_month: darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
    }
  },
  statistic: {
    width: "462px",
    marginTop: "36px",
    minHeight: "586px",
    display: "flex",
    flexDirection: "column",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
        height: "auto",
      }
    
  },
  statistic_box: darkTheme => {
    return {
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
      padding: "16px",
      boxShadow: darkTheme ? "0px 20px 27px rgba(255, 255, 255, 0.05)" : "0px 20px 27px rgba(0, 0, 0, 0.05)",
      backgroundColor: darkTheme ? "transparent" : "white",
      borderRadius: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },
  txs_box: darkTheme => {
    return {
      justifyContent: "space-between",
      padding: "16px",
      boxShadow: darkTheme ? "0px 20px 27px rgba(255, 255, 255, 0.05)" : "0px 20px 27px rgba(0, 0, 0, 0.05)",
      backgroundColor: darkTheme ? "transparent" : "white",
      borderRadius: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
        height: "auto",
      }
    }
  },
  statistic_box_col: darkTheme => {
    return {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      boxShadow: darkTheme ? "0px 20px 27px rgba(255, 255, 255, 0.05)" : "0px 20px 27px rgba(0, 0, 0, 0.05)",
      backgroundColor: darkTheme ? "transparent" : "white",
      borderRadius: "16px",
      fontWeight: "bold",
      alignItems: "center",
      "@media (max-width: 767.98px)": {
        height: "auto",
      }
    }
  },
  statistic_box_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
      "@media (max-width: 767.98px)": {

        height: "auto",
      }
  },
  statistic_box_title: darkTheme => {
    return {
      fontSize: "14px",
      margin: 0,
      color: darkTheme ? "white" : "#67748E",
    }
  },
  statistic_box_value: {
    fontSize: "20px",
    margin: 0,
  },
  statistic_box_sub_value: {
    fontSize: "14px",
    color: "#13C39C",
  },
  bottom_container: {
    marginLeft: "52px",
    marginRight: "40px",
    marginTop: "25px",
    background: "linear-gradient(90.67deg, #2698D4 0%, #252525 100%)",
    boxShadow: "0px 20px 27px rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bottom_title: {
    paddingLeft: "47px",
    fontWeight: "bold",
    fontSize: "47px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "14px",
      }
  },
  bottom_value: {
    paddingRight: "16px",
    fontWeight: "bold",
    fontSize: "40px",
    color: "white",
      "@media (max-width: 767.98px)": {
        fontSize: "22px",
      }
  },
  regbutton: {
    background: "linear-gradient(135deg, #252525 0%, #2698D4 100%)",
    boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)",
    borderRadius: "8px",
    width: "108px",
    height: "42px",
    lineHeight: "42px",
    textAlign: "center",
    cursor: "pointer",
    margin: "4px",

  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  textes: {
    fontWeight: "800",
    fontSize: "14px",
    textAlign: "center",
  },
}));

const Cryptodeposit = (props) => {
  const { darkTheme, refcode, btcperc, btcprice, btcaddress, email, ustxs, token } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const labelColor = darkTheme ? 'white' : 'gray'
  
    
  const changetab = (tab) => {
    setStore({ 
      activeWalletTab: tab,
    });
  };

  
  const renderStatistic = (title, value, subValue = "") => (
    <div className={classes.statistic_box}>
      <div className={classes.statistic_box_content}>
        <p className={classes.statistic_box_title}>{title}</p>
        <p className={classes.statistic_box_value}>
          {value}&nbsp;&nbsp;
          <span className={classes.statistic_box_sub_value}>
            {subValue}
          </span>
        </p>
      </div>
      <div className={classes.profit_value}> 
            <div className={classes.regbutton} onClick={()=>{changetab("deposit")}} >
              Deposit
            </div>
            <div className={classes.regbutton} onClick={()=>{changetab("withdraw")}} >
              Withdraw
            </div>
      </div>
    </div>
  );
  
  return (
    <div className={classes.root}>
      <div className={classes.top_container}>
        <div className={classes.profit}>
          <p className={classes.profit_title}>Wallet Deposit</p>
          <div className={classes.profit_value}>
            
            
            <p className={classes.percent_value}>BTC&nbsp;&nbsp;<span className={classes.percent_month}>0.00</span></p>
          </div>

        </div>
        <div className={classes.statistic}>

          <div className={classes.txs_box}>
            <p className={classes.ptxs}>Transactions history</p>
            
              <div className={classes.row}>
                <div className={classes.cell}>
                
                </div>
                <div className={classes.cell}>
              
                </div>
                <div className={classes.cell}>
                
                </div>
              </div>

          </div>
        </div>
      </div>
      <div className={classes.bottom_container}>
        <p className={classes.bottom_title}>Live Price Tracker</p>
        <p className={classes.bottom_value}>{btcprice}</p>
      </div>
    </div>
  )
}

export default Cryptodeposit;
