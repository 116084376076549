import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { getData } from "../../store/appStoreSlice";
import Home from "./Home/Home";
import Trades from "./Trades/Trades";
import DepositAll from "./Depositwithdraw/DepositAll";
import WithdrawAll from "./Depositwithdraw/WithdrawAll";
import Transactions from "./Transactions/Transactions";
import Help from "./Help/Help";
import Deposit from "./Deposit/Deposit";
import Withdraw from "./Withdraw/Withdraw";
import Cryptodeposit from "./Cryptodeposit/Cryptodeposit";
import SignInPage from "../SignInPage/SignInPage";
import { setTheme, setStore, exitloc, logintocore, checkall } from "../../utils";
import { makeStyles } from '@mui/styles';
import { useFBAuth } from "../../providers";
import { googleLogout } from '@react-oauth/google';



const MaterialUISwitch = styled(Switch)(({ darkmode }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: darkmode === false ? 'white' : '#2698D4',
        border: darkmode === false ? "1px solid #4F4F4F" : '#2698D4',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: darkmode === true ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: darkmode === false ? 'white' : '#2698D4',
    borderRadius: 20 / 2,
    border: darkmode === false ? "1px solid #4F4F4F" : '#2698D4',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      display: "flex",
      fontFamily: "HelveticaNeueCyr !important",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
      }
    }
  },
  tab_bar: darkTheme => {
    return {
      width: "78px",
      "@media (max-width: 767.98px)": {
        flexDirection: "row",
        display: "flex",
        width: "100%",
      }
    }
  },
  tab_logo: {
    marginTop: "31px",
    marginLeft: "14px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  bluefont: {
    color: "#2698D4",
  },
  tab_buttons: {
    marginTop: "96px",
    width: "100%",
    height: "180px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "row",
        height: "auto",
      }
  },
  tab_button: {
    margin: "0 14px",
    height: "35px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  tab_content: {
    width: "calc(100% - 78px)",
    minHeight: "100vh",
      "@media (max-width: 767.98px)": {
        margin: "6px",
        width: "100%",
      }
  },
  tab_content_header: {
    marginLeft: "77px",
    marginRight: "54px",
    display: "flex",
    justifyContent: "space-between",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        width: "100%",
      }
  },
  tab_content_header_link: {
    fontWeight: "700",
    display: "flex",
    width: "100%",
    justifyContent: "center",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  tab_content_column: {
    marginLeft: "77px",
    marginRight: "54px",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        width: "100%",
      }
  },

  active_tab: {
    color: "#2698D4",
    textDecoration: "underline",
    margin: "0 14px",
    textAlign: "center",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar_content: {
    display: "flex",
    alignItems: "center",
    cursor:"pointer",
  },
  popuptop: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",

    justifyContent: "center",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
  avatar_text: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  theme_switch: {
    bottom: "30px",
    left: "20px",
    position: "absolute",
   
      "@media (max-width: 767.98px)": {
         position: "initial",
      }
  },
}));

const WalletPage = (props) => {
  const { darkTheme, username, activeWalletTab, signedIn, isSignedIn, mqttKey, email, token, BTCZAR, ETHZAR, coins } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [total, setTotal] = useState("");

  const [{ loaded, currentUser, isLoggedIn }, login, logout] = useFBAuth();
  
  const handleThemeChange = (e) => {
    setTheme(!darkTheme);
  }
  const setSignOut = () => {
    exitloc();
    if (isSignedIn ) { 
      googleLogout();
    }
    if (isLoggedIn ) { 
      logout();
    }
    exitloc();
  }
  const changetab = (tab) => {
    if (activeWalletTab === tab)
      return;

    setStore({ 
      activeWalletTab: tab,
    });
  };
   
  useEffect(() => {
    var sumzar = Number(coins["BTC"].balance)*(Number(BTCZAR)) + Number(coins["ETH"].balance)*(Number(ETHZAR)) + Number(coins["ZAR"].balance);
    setTotal('R' + sumzar.toFixed());
  }, [BTCZAR, coins, ETHZAR]);

  useEffect(() => {
    if (!mqttKey && signedIn && email && token) {
      logintocore(email, token, "");
    }
    
  }, [mqttKey, email, token, signedIn]);
  
  useEffect(() => {
    if (mqttKey) {
      checkall();
    }
  }, [mqttKey]);
  
  const renderHome = () => (<Home/>);
  const renderTrades = () => (<Trades/>);
  const renderTransactions = () => (<Transactions/>);
  const renderHelp = () => (<Help/>);
  const renderDeposit = () => (<Deposit/>);
  const renderCRDeposit = () => (<Cryptodeposit/>);
  const renderWithdraw = () => (<Withdraw/>);
  const renderDepositAll = () => (<DepositAll/>);
  const renderWithdrawAll = () => (<WithdrawAll/>);
  
  const renderTabButton = (tabName,txtName) => (
    <a 
      onClick={() => changetab(tabName)} 
      className={ activeWalletTab === tabName ? classes.active_tab : classes.tab_button }
    >{txtName}</a>
  );

  const renderSignIn = () => {
    return (
      <div className={ signedIn ? classes.hide : classes.popuptop }>
         <SignInPage />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.tab_bar}>
        <img className={classes.tab_logo} src="/img/logo.png" width="50" height="50"/>
        <div className={classes.tab_buttons}>
          <Link to="/dashboard"><img src={`/img/home.png`} width="20" height="20"/></Link>
          <Link to="/setting"><img src={`/img/verify.png`} width="20" height="20"/></Link>
          <Link to="/wallet"><img src={`/img/wallet_active.png`} width="20" height="20"/></Link>
          <Link to="/trade"><img src={`/img/trade.png`} width="20" height="20"/></Link>
        </div>
        <FormControlLabel
          classes={{root: classes.theme_switch}}
          control={<MaterialUISwitch darkmode={darkTheme} onChange={handleThemeChange}/>}
          label=""
        />
      </div>
      <div className={classes.tab_content}>
        <div className={classes.tab_content_header}>
          <h2></h2>
          <div className={classes.avatar_content} onClick={setSignOut}>
            <p className={classes.avatar_text}>Sign Out&nbsp;&nbsp;&nbsp;</p>
            { darkTheme
              ? <img src="/img/avatar.png" width="21" height="21"/>
              : <img src="/img/avatar_gray.png" width="21" height="21"/>
            }
          </div>
        </div>
        <div className={classes.tab_content_column}>
          <h2>Portofolio <span className={classes.bluefont}>Balance</span><br />{total}</h2>
        </div>
        <div className={classes.tab_content_header_link}>
          { renderTabButton('wallet', 'Balances') }
          { renderTabButton('trades', 'Trades') }
          { renderTabButton('depositall', 'Deposit') }
          { renderTabButton('withdrawall', 'Withdraw') }
          { renderTabButton('txhistory', 'Transactions') }

        </div>
        { activeWalletTab === 'wallet' && renderHome() }
        { activeWalletTab === 'trades' && renderTrades() }
        { activeWalletTab === 'txhistory' && renderTransactions() }
        { activeWalletTab === 'depositall' && renderDepositAll() }
        { activeWalletTab === 'withdrawall' && renderWithdrawAll() }
        { activeWalletTab === 'help' && renderHelp() }
        { activeWalletTab === 'deposit' && renderDeposit() }
        { activeWalletTab === 'cryptodeposit' && renderCRDeposit() }
        { activeWalletTab === 'withdraw' && renderWithdraw() }
      </div>
      { !signedIn && renderSignIn() }
    </div>
  )
}

export default withRouter(WalletPage);
