import React from 'react';
import { GoogleLoginButton } from "react-social-login-buttons";
import { useGoogleLogin } from '@react-oauth/google';
import { setStore, refreshTokenSetup } from "../../utils";

const GoogleLogin = (props) => {

  const login = useGoogleLogin({
    onSuccess: tokenResponse => onSuccess(tokenResponse),
    autoSelect: true,
    auto_select: true,

  });
  
  const onSuccess = (res) => {
    console.log('Login ok: res:', res);
    refreshTokenSetup(res);
   
  };

  return (
    <GoogleLoginButton 
      onClick={login}
      style={{
      backgroundColor: "transparent", 
      border: "1px solid #E5E5E5", 
      width: "200px", 
      height: "45px", 
      display: "inline-block",
      fontSize: "12px",
      color: "#E5E5E5",
      margin: "auto",
      }} 
      activeStyle={{backgroundColor: "#454545"}}
    >
      <span>Sign up with Google</span>
    </GoogleLoginButton>
  );
}

export default GoogleLogin;
