import { InputAdornment, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";
import { setStore } from "../../../utils";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }
  },
  title: {
    marginTop: "46px",

    fontWeight: "600",
    fontSize: "14px",
    textAlign: "center",
    color: "#67748E",
  },
  description: {
    fontWeight: "600",
    fontSize: "24px",
  },

  email_root:darkTheme => {
    return {
      padding: "10px",
      width: "329px",
      background: "rgba(196, 196, 196, 0.1)",
      boxSizing: "border-box!important",
      borderRadius: "20px!important",
      color: darkTheme ? "white" : "black",
    }
  },
  sub_description: {
    width: "355px",
    fontWeight: "600",
    fontSize: "14px",
    textAlign: "center",
    color: "#67748E",
  },
  sub_description_with_margin: {
    width: "355px",
    fontWeight: "600",
    fontSize: "14px",
    textAlign: "center",
    color: "#67748E",
    marginBottom: "121px",
  },
  verify_description_box: {
    width: "355px",
    display: "flex",
    alignItems: "center",
  },
  yes_no_box: {
    width: "171px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "82px",
    marginBottom: "76px",
  },
  verify_description: {
    marginLeft: "30px",
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  verify_description_1: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
    textAlign: "center",
    width: "100%",
  },
  start_button: {
    marginTop: "30px!important",
    height: "45px",
    width: "300px",
    backgroundColor: "#2698D4!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "#2698D4!important",
    }
  },
  progress_bar: {
    width: "168px",
    backgroundImage: "url(/img/upload.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  success_bar: {
    width: "168px",
    backgroundImage: "url(/img/success.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  uploading_bar: {
    width: "168px",
    backgroundImage: "url(/img/uploading.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  img_download: {
    marginTop: "46px",
    marginBottom: "20px",
    cursor: "pointer",
  },
  img_yes_no: {
    cursor: "pointer",
  },
  img_big_yes: {
    marginTop: "33px",
    marginBottom: "27px",
  },
  file_upload: {
    display: "none",
  },
  file_input: {
    cursor: "pointer",
  },
}));

const Yesno = (props) => {
  const { darkTheme, email, username, provider, refcode, verified } = useSelector(getData);
  const classes = useStyles(darkTheme);


  const handleAction = () => {
      setStore({ 
        activeTab: 'allver',
      });
      
  }

  const handleNo = () => {
      setStore({ 
        activeTab: 'home',
      });
      
  }


  return (
    <div className={classes.root}>
      <p className={classes.title}>Documents</p>
      <p className={classes.description}>Do you want to buy</p>
      <p className={classes.description}>More than R24000 per day?</p>

      <div className={classes.yes_no_box}>
        <img className={classes.img_yes_no} src="/img/yes.png" width="50" height="50" onClick={handleAction}/>
        <img className={classes.img_yes_no} src="/img/no.png" width="50" height="50" onClick={handleNo}/>
      </div>
    </div>

    
    
    
  );
};

export default Yesno;
