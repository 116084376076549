import { InputAdornment, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../store/appStoreSlice";


const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "77px",
    }
  },
  referral: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "24px",
  },
  referral_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  feature_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "195px",
    width: "765px",
    marginLeft: "39px",
    marginTop: "30px",
  },
  feature_item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "200px",
  },
  feature_txt: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
    textAlign: "center",
    marginTop: "50px",
  },
  invite: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "30px",
  },
  invite_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  email_root: {
    marginTop: "10px",
    width: "929px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
  },
  email_input: {
    color: "#67748E!important",
  },
  link: {
    fontWeight: "600",
    fontSize: "24px",
    margin: 0,
    marginTop: "30px",
  },
  link_description: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#67748E",
  },
  link_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "929px",
    marginTop: "10px",
  },
  link_root: {
    width: "741px",
    background: "rgba(196, 196, 196, 0.1)",
    boxSizing: "border-box!important",
    borderRadius: "20px!important",
  },
  link_input: {
    color: "#67748E!important",
  },
  link_button: {
    color: "#2698D4",
    textDecoration: "none",
    cursor: "pointer",
  }
}));

const Referral = (props) => {
  const { darkTheme,refcode } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [values, setValues] = useState({
    email: '',
    link: '',
  });
  const [code, setCode] = React.useState('https://app.ankerpay.com/')
  
  useEffect(() => {
    setCode('https://app.ankerpay.com/dashboard?referrals='+refcode);
  }, [refcode]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const renderFeatureItem = (img, txt) => (
    <div className={classes.feature_item}>
      <img src={img} width="90" height="90"/>
      <p className={classes.feature_txt}>{txt}</p>
    </div>
  )

  return (
    <div className={classes.root}>
      <h2 className={classes.referral}>Referrals</h2>
      <p className={classes.referral_description}>Invite friens to AnkerPay App and recieve up to R5000 rand cashback once they have finished their SDA (Single discrepancy allowance). </p>
      <div className={classes.feature_box}>
        { renderFeatureItem("/img/feature_link.png", "Send your referal link to friends and tell them how to join.") }
        { renderFeatureItem("/img/feature_register.png", "Let them register to AnkerPay Arbitrage using your referal link.") }
        { renderFeatureItem("/img/feature_cash.png", "Earn up to R5000 cashback for each friend refered that complete their SDA.") }
      </div>
      <h2 className={classes.invite}>Invite your friends</h2>
      <p className={classes.invite_description}>Insert your friends’ email address and send them and invite to join AnkerPay Arbitrage.</p>
      <OutlinedInput
        id="outlined-adornment-email"
        placeholder="Email Address"
        value={values.email}
        onChange={handleChange('email')}
        classes={{root: classes.email_root, input: classes.email_input}}
        endAdornment={<InputAdornment position="end"><a href="#"><img src="/img/email_address_send.png" width="30" height="30"/></a></InputAdornment>}
        aria-describedby="outlined-weight-helper-text"
        labelWidth={0}
      />
      <h2 className={classes.link}>Referral Link</h2>
      <p className={classes.link_description}>Insert your friends’ email address and send them and invite to join AnkerPay Arbitrage.</p>
      <div className={classes.link_box}>
        <OutlinedInput
          id="outlined-adornment-link"
          value={values.link}
          placeholder={code}
          onChange={handleChange('link')}
          classes={{root: classes.link_root, input: classes.link_input}}
          endAdornment={<InputAdornment position="end">
                          <a className={classes.link_button} onClick={() => {navigator.clipboard.writeText(values.link)}}>Copy link</a>
                        </InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
          }}
          labelWidth={0}
        />
        <a href="#"><img src="/img/facebook.png" width="45" height="45"/></a>
        <a href="#"><img src="/img/twitter.png" width="45" height="45"/></a>
        <a href="#"><img src="/img/linkedin.png" width="45" height="45"/></a>
      </div>
    </div>
  );
};

export default Referral;
