import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { getData } from "../../store/appStoreSlice";
import Trade from "./Trade/Trade";
import SignInPage from "../SignInPage/SignInPage";
import { setTheme, setStore, exitloc, logintocore, checkall } from "../../utils";
import { makeStyles } from '@mui/styles';
import { useFBAuth } from "../../providers";
import { googleLogout } from '@react-oauth/google';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './exchange.css';

const MaterialUISwitch = styled(Switch)(({ darkmode }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: darkmode === false ? 'white' : '#2698D4',
        border: darkmode === false ? "1px solid #4F4F4F" : '#2698D4',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: darkmode === true ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: darkmode === false ? 'white' : '#2698D4',
    borderRadius: 20 / 2,
    border: darkmode === false ? "1px solid #4F4F4F" : '#2698D4',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
      }
    }
  },
  tab_bar: darkTheme => {
    return {
        flexDirection: "row",
        display: "flex",
        width: "100%",
    }
  },
  tab_logo: {
    margin: "4px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
      }
  },
  tab_logoss: {
    width: "280px",
    minWidth: "280px",
    border: "1px solid rgba(176, 203, 217, 0.5)",
  },
  tab_buttons: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "13px",
    borderTop: "1px solid rgba(176, 203, 217, 0.5)",
    borderBottom: "1px solid rgba(176, 203, 217, 0.5)",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "row",
        height: "auto",
      }
  },
  tab_buttons_end: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    width: "100%",
    fontFamily: 'Montserrat',
    fontWeight: "600",
    border: "1px solid rgba(176, 203, 217, 0.5)",
    borderLeft: "none",
    fontSize: "13px",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        flexDirection: "row",
        height: "auto",
      }
  },
  tab_content: {
    width: "100%",
      "@media (max-width: 767.98px)": {
        margin: "6px",
        width: "100%",
      }
  },
  tab_content_header: {

    marginRight: "54px",
    display: "flex",
    justifyContent: "space-between",
      "@media (max-width: 767.98px)": {
        margin: "0px",
        width: "100%",
      }
  },
  popuptop: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",

    justifyContent: "center",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
  active_tab: {
    textAlign: "center",
    width: "82px",
    alignItems: "center",
    borderRight: "1px solid rgba(176, 203, 217, 0.5)",
    borderLeft: "none",
    borderBottom: "2px solid rgba(176, 203, 217, 1)",
    textDecoration: "none",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  tab_button: darkTheme => {
    return {
      color: darkTheme ? "white" : "black",
      width: "82px",
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer",
      borderRight: "1px solid rgba(176, 203, 217, 0.5)",

      textDecoration: "none",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      
    }
  },
  avatar_content: {
    display: "flex",
    alignItems: "center",
    cursor:"pointer",
  },
  tab_header: {
    margin: "14px",
  },
  spanpionter: {
    cursor: "pointer",
    fontFamily: 'Montserrat',
    margin: "16px 6px",
    fontWeight: "600",
    fontSize: "15px",
  },
  popupcoin: {
    display: "flex",

  },
  popuptab: {
    margin: "16px 6px",
    cursor: "pointer",
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "15px",
  },
  popupdiv: {
    margin: "16px 6px",
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "15px",
  },
  summ_header: {
    display: "flex",
    alignItems: "center",
  },
  zero_marginh: {
    margin: "0px",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "9px",
    lineHeight: "12px",
    color: "rgb(255, 255, 255)",
  },
  zero_margin: {
    margin: "0px",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    color: "rgb(255, 255, 255)",
  },
  avatar_text: darkTheme => {
    return {
      color: darkTheme ? "white" : "#67748E",
    }
  },
  theme_switch: {
      borderRight: "1px solid rgba(176, 203, 217, 0.5)",

  },
}));

const ExchangePage = (props) => {
  const { darkTheme,  activeExTab, signedIn, email, token, isSignedIn, pair, change24, volume24h, volume24hBTC, lastTrade, low, hight, mqttKey } = useSelector(getData);
  const classes = useStyles(darkTheme);
  
  const { sendMessage, lastMessage, readyState } = useWebSocket('wss://app.ankerpay.com/wss/');

  
  const [prevpair, setPrevpair] = useState(null);
    
  const [soketid, setSoketid] = useState(1);
  const [coinpopup, setCoinpopup] = useState(false);
  const [opened, setOpened] = useState(false);
  const [authid, setAuthid] = useState(null);
  
  const [lasttradevalue, setLasttradevalue] = useState(0);
  const [lasttradeData, setLasttradeData] = useState([]);
  const [chartData, setChartData] = useState({asks:[], bids:[]});
  
  const [{ isLoggedIn }, logout] = useFBAuth();
  
  
  const handleThemeChange = (e) => {
    setTheme(!darkTheme);
  }
  const setSignOut = () => {
    exitloc();
    if (isSignedIn ) { 
      googleLogout();
    }
    if (isLoggedIn ) { 
      logout();
    }
    exitloc();
  }
  
  const changetab = (tab) => {
    if (activeExTab === tab)
      return;

    setStore({ 
      activeExTab: tab,
    });
  };


  
  
  useEffect(() => {
    if (!mqttKey && signedIn && email && token) {
      toast("Connecting ...");
      logintocore(email, token, "");
    }
  }, [email, token, signedIn]);
  
  useEffect(() => {
    if (mqttKey) {
      checkall();
    }
  }, [mqttKey]);
  
  
  useEffect(() => {
    if (opened && mqttKey) {
        setAuthid(soketid);
        let data = {
          id: soketid,
          method: 'server.auth',
          params: [mqttKey,'app.ankerpay.com',],
        };
        sendMessage(JSON.stringify(data));
        setSoketid(soketid+1);
    }
  }, [mqttKey, opened]);
  
  useEffect(() => {
    if (prevpair) {
      setStore({ 
        volume24h: 0,
        volume24hBTC: 0,
        sellPrice: 0,
        buyPrice: 0,
        lastTrade: 0,
        lastTradeCh: 0,
        change24: 0,
        low: 0,
        hight: 0,
        bids: [],
        asks: [],
        lastDeals: [],
        openOrders: [],
      });
      setPrevpair(pair);
      
      setLasttradevalue(0);
      setLasttradeData([]);
      setChartData({asks:[], bids:[]});
      
      unsubscribe()
      .then(() => subscribe());
    } else { 
      setPrevpair(pair);
    }
    toast(pair);
  }, [pair]);


  useEffect(() => {
    if (lastMessage !== null) {
      getmessage(lastMessage);
    }
  }, [lastMessage]);


  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      subscribe();
      setOpened(true);
    }
  }, [readyState]);


  const subscribeOrder = async () => {
    console.log('SUBSCRIBE ORDERS')
    let symbol = pair.replace('/','');
    var tempsoketid = soketid
    let data = {
      id: tempsoketid,
      method: 'order.subscribe',
      params: [symbol,], 
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    
    data = {
      id: tempsoketid,
      method: 'asset.subscribe',
      params: [], //book
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    

    setSoketid(tempsoketid);

  }


  const subscribe = async () => {
    console.log('SUBSCRIBE')
    let symbol = pair.replace('/','');
    var tempsoketid = soketid
    let data = {
      id: tempsoketid,
      method: 'depth.subscribe',
      params: [symbol,50,'0',], //book
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    
    data = {
      id: tempsoketid,
      method: 'depth.query',
      params: [symbol,50,'0',], //book
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    

    
    data = {
      id: tempsoketid,
      method: 'deals.subscribe',
      params: [symbol,], //book
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    
    data = {
      id: tempsoketid,
      method: 'deals.query',
      params: [symbol,50,0,],
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    

    
    data = {
      id: tempsoketid,
      method: 'state.subscribe',
      params: [symbol,], 
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    
    data = {
      id: tempsoketid,
      method: 'state.query',
      params: [symbol,86400,], 
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    setSoketid(tempsoketid);

  }

  const unsubscribe = async () => {
console.log('UNSUBSCRIBE')

    var tempsoketid = soketid
    let data = {
      id: tempsoketid,
      method: 'depth.unsubscribe',
      params: [], 
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;

    data = {
      id: tempsoketid,
      method: 'deals.unsubscribe',
      params: [], //book
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;

    data = {
      id: tempsoketid,
      method: 'state.unsubscribe',
      params: [], 
    };
    sendMessage(JSON.stringify(data));
    tempsoketid = tempsoketid + 1;
    setSoketid(tempsoketid);
  }

  const emitBooks = (newasks,newbids,needclear) => {
    var tempchartData = {asks:chartData.asks.slice(), bids:chartData.bids.slice()}
    if (needclear) {
      tempchartData = {asks:[], bids:[]}
    }
    if (tempchartData.asks && newasks) {
      newasks.forEach((item) => {
        var index = tempchartData.asks.findIndex(x => x.price == item.price);
        if (index > -1) { 
            tempchartData.asks[index] = {price:item.price, amount: item.amount};
        } else {
          if (item.amount > 0) { tempchartData.asks.push({price:item.price, amount: item.amount}); }
        }
      });
    } else {
      if (newasks) {
        tempchartData.asks = newasks.slice();
      }
    }
    if (tempchartData.bids && newbids) {
      newbids.forEach((item) => {
        var index = tempchartData.bids.findIndex(x => x.price == item.price);
        if (index > -1) {
            tempchartData.bids[index] = {price:item.price, amount: item.amount};
        } else {
          if (item.amount > 0) { tempchartData.bids.push({price:item.price, amount: item.amount}); }
        }
      });
    } else {
        if (newbids) {
          tempchartData.bids = newbids.slice();
        }
    }
    tempchartData.asks.sort(function(a, b) {
      return a.price - b.price;
    });
    tempchartData.bids.sort(function(a, b) {
      return b.price - a.price;
    });

    var njbids = tempchartData.bids.filter(function(item) {
      return item.amount > 0
    });
    var njasks = tempchartData.asks.filter(function(item) {
      return item.amount > 0
    });
    
    setStore({ 
       asks:njasks, 
       bids:njbids
    });
    setChartData({asks:njasks, bids:njbids})
    
    if ( njasks && njasks.length > 0 ) {
      setStore({ 
        buyPrice: njasks[0].price,
      });
    }
    if ( njbids && njbids.length > 0 ) {
      setStore({ 
        sellPrice: njbids[njbids.length - 1].price,
      });
    }
   
  }
  
  const getmessage = (message) => {

    let dataObj = JSON.parse(message.data);
    let returnid = dataObj.id;
    if (returnid && authid && returnid == authid) {
      subscribeOrder();
    }
    let method = dataObj.method;
    if (method) {
      console.log(method);
      if (method=='depth.update') {
        let data = dataObj.params[1];
        let needclear = dataObj.params[0];
        let newasks = [];
        let newbids = [];
        if (data.asks) {
          data.asks.forEach((item) => {
            newasks.push({'price':Number(item[0])>1 ? Math.floor(item[0]) : Number(item[0]),'amount':Number(item[1]),});
          });
        }
        if (data.bids) {
          data.bids.forEach((item) => {
            newbids.push({'price':Number(item[0])>1 ? Math.floor(item[0]) : Number(item[0]),'amount':Number(item[1]),});
          });
        }


        emitBooks(newasks,newbids,needclear);

      }
      if (method=='price.update') {
        let data = dataObj.params[1];

        console.log(data,"price.update");
      }
      if (method=='order.update') {
        let data = dataObj.params[1];

        console.log(data,"order.update");
        checkall();
        
      }

      if (method=='asset.update') {
        let data = dataObj.params[1];

        console.log(data,"asset.update");
      }

      if (method=='deals.update') {
        let data = dataObj.params[1];

        var newlasttradeData = [...data, ...lasttradeData]
        var morethanneed = newlasttradeData.length - 15;
        if (morethanneed > 0) {
          newlasttradeData.splice(14, morethanneed);
        }
        setLasttradeData(newlasttradeData);
        setStore({ 
          lastDeals: newlasttradeData.slice(),
        });
      }
      if (method=='state.update') {
        let data = dataObj.params[1];
        
        setStore({ 
          volume24h: Math.floor(data.deal),
          volume24hBTC: Math.floor(data.volume),
          lastTrade: Number(data.last)>1 ? Math.floor(data.last) : Number(data.last),
          lastTradeCh: lasttradevalue > Number(data.last) ? 0 : 1,
          low: Number(data.low)>1 ? Math.floor(data.low) : Number(data.low),
          hight: Number(data.high)>1 ? Math.floor(data.high) : Number(data.high),
          change24: Math.floor((1-(Number(data.open) / Number(data.last)))*10000)/100
        });
        setLasttradevalue(Number(data.last));
      }
    }
  };
  
  const renderTrade = () => (<Trade/>);
  const setPair = (p) => {
    setStore({ pair: p });
    setCoinpopup(!coinpopup);
  };

  const renderCoinselect = () => {
    var nonfirst = false
    return (
      ["BTC/ZAR","ETH/ZAR","ETH/BTC"].map((item, index)=>{

        if ( item !== pair ) {
          if ( nonfirst ) {
            return (
              <>
                <h2 className={classes.popupdiv}> | </h2>
                <h2 className={classes.popuptab} onClick={() => setPair( item )} >{item}</h2>
              </>
              )
          } else {
            nonfirst = true
            return (
              <h2 className={classes.popuptab} onClick={() => setPair( item )} >{item}</h2>
              )
          }

        }

      })
    )
    
  }
      
  

  const renderSignIn = () => {
    return (
      <div className={ signedIn ? classes.hide : classes.popuptop }>
         <SignInPage />
      </div>
    )
  }
      
 



  const renderTabButton = (tabName, tabText) => (
    <a 
      onClick={() => changetab(tabName)} 
      className={ activeExTab === tabName ? classes.active_tab : classes.tab_button }
    >
      { tabText }
    </a>
  );
  

  return (
    <div className={classes.root}>
      <div className={classes.tab_bar}>
        <div className={classes.tab_logoss}>
          <img className={classes.tab_logo} src="/img/logo.png" width="46" height="46"/>
        </div>
        <div className={classes.tab_buttons}>
          { renderTabButton('trade','Trade Pro') }
          { renderTabButton('wallet','Buy/Sell') }
          <Link to="/wallet" className={classes.tab_button}>Wallets</Link>
          <Link to="/wallet" className={classes.tab_button}>Deposit</Link>
          <Link to="/wallet" className={classes.tab_button}>Withdraw</Link>
        </div>
        <div className={classes.tab_buttons_end}>  
          <FormControlLabel
            classes={{root: classes.theme_switch}}
            control={<MaterialUISwitch darkmode={darkTheme} onChange={handleThemeChange}/>}
            label=""
          />
          
          <Link to="/dashboard" className={classes.tab_button}>Profile</Link>
          <Link to="/setting" className={classes.tab_button}>Settings</Link>

        </div>
      </div>
      <div className={classes.tab_content}>
        <div className={classes.tab_content_header}>
          <div className={classes.popupcoin}>
            <h2  className={classes.spanpionter} onClick={() => setCoinpopup(!coinpopup)}>{pair}</h2><h2 className={classes.popupdiv}> | </h2>
            { coinpopup && renderCoinselect() }
          </div>
          <div className={classes.summ_header}>
            <div className={classes.tab_header}>
              <h6 className={classes.zero_marginh}>Last Traded</h6>
              <p className={classes.zero_margin}>{lastTrade} ZAR</p>
            </div>
            <div className={classes.tab_header}>
              <h6 className={classes.zero_marginh}>24h Change</h6>
              <p className={classes.zero_margin}>{change24} %</p>
            </div>
            <div className={classes.tab_header}>
              <h6 className={classes.zero_marginh}>24h Low</h6>
              <p className={classes.zero_margin}>{low}</p>
            </div>
            <div className={classes.tab_header}>
              <h6 className={classes.zero_marginh}>24h Volume ZAR</h6>
              <p className={classes.zero_margin}>{volume24h} ZAR</p>
            </div>
            <div className={classes.tab_header}>
              <h6 className={classes.zero_marginh}>24h High</h6>
              <p className={classes.zero_margin}>{hight}</p>
            </div>
            <div className={classes.tab_header}>
              <h6 className={classes.zero_marginh}>24h Volume BTC</h6>
              <p className={classes.zero_margin}>{volume24hBTC} BTC</p>
            </div>
            <div className={classes.avatar_content} onClick={setSignOut}>
              <p className={classes.avatar_text}>Sign Out&nbsp;&nbsp;&nbsp;</p>
              { darkTheme
                ? <img src="/img/avatar.png" width="21" height="21"/>
                : <img src="/img/avatar_gray.png" width="21" height="21"/>
              }
            </div>
          </div>
        </div>
        { activeExTab === 'trade' && renderTrade() }
      </div>

        { !signedIn && renderSignIn() }

    </div>
  )
}

export default withRouter(ExchangePage);
