import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import SignUpPage from "./components/SignUpPage/SignUpPage";
import DashboardPage from "./components/DashboardPage/DashboardPage";
import SettingsPage from "./components/SettingsPage/SettingsPage";
import WalletPage from "./components/WalletPage/WalletPage";
import PeachExchange from "./components/PeachPage/PeachExchange";
import PeachPage from "./components/PeachPage/PeachPage";
import PeachStatus from "./components/PeachPage/PeachStatus";
import ExchangePage from "./components/ExchangePage/ExchangePage";
import { useFBAuth } from "./providers";
import store from './store';
import { setData, getData } from './store/appStoreSlice';
import SignInPage from "./components/SignInPage/SignInPage";
import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';

import { getPrice, loginIn } from './utils';

const App = (props) => {

  const [{ loaded, currentUser, isLoggedIn }, login, logout] = useFBAuth();
  const { email, username, provider, signedIn, isSignedIn, googleUser, darkTheme} = useSelector(getData);
  
  useEffect(() => {
    getPrice();
  }, []);
  
  useEffect(() => {

    if (username) {
      loginIn(email, username, provider);
    }
  }, [email, username, provider]);
    
  useEffect(() => {
    if (isLoggedIn) {
      store.dispatch(setData({
        signedIn: true,
        provider: 'fb',
        username: currentUser.name,
        email: currentUser.email,
      }));    
    }
  }, [isLoggedIn]);
  
  useEffect(() => {
    if (isSignedIn) {
      store.dispatch(setData({
        signedIn: true,
        provider: 'google',
      }));
    }
  }, [isSignedIn]);
  return (
    <div className="App">
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route  path="/signup" exact render={() =>
        signedIn ? <Redirect to="/dashboard" /> : <SignUpPage />
      } />
      <Route  path="/signin" exact render={() =>
        signedIn ? <Redirect to="/dashboard" /> : <SignInPage />
      } />
      <Route  path="/dashboard" exact render={() =>
        !signedIn ? <Redirect to="/signup" /> : <DashboardPage />
      } />
      <Route  path="/wallet" exact render={() =>
        <WalletPage />
      } />
      <Route  path="/setting" exact render={() =>
        <SettingsPage />
      } />
      <Route  path="/tradebot" exact render={() =>
        <PeachExchange />
      } />
      <Route  path="/trade" exact render={() =>
        <ExchangePage />
      } />
      <Route  path="/checkout" exact render={() =>
        <PeachExchange />
      } />
      <Route  path="/checkout-status/:checkoutid" exact render={() =>
        <PeachStatus />
      } />
      <ToastContainer theme={darkTheme ? "dark" : "light"} />
    </div>
  );
}

export default App;
