import React, { useEffect, useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getData } from "../../store/appStoreSlice";
import { IconButton, InputAdornment, Input, TextField, Button } from "@mui/material";
import { MainButton, useShowPopup, WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import {Helmet} from "react-helmet";
import { getbtc, neworder } from "../../utils";


const useStyles = makeStyles((theme) => ({
  root: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      color: darkTheme ? "white" : "black",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 767.98px)": {
        flexDirection: "column",
      }
    }
  },

  title: {
    textAlign: "center",
  },
  greytitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
  },
  greyclicktitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "14px",
    cursor:"pointer",
  },
  flex_forinput: {
    position: "relative",
    padding: "0 14px",
  },
  flex_g_input:darkTheme => {
    return {
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "8px",
      
    }
  },
  flex_g_select:darkTheme => {
    return {
      backgroundColor: "rgba(176, 203, 217, 0.2)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      padding: "8px",
      marginLeft: "10px",
      width: "100%",
    }
  },
  flex_g: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 14px",
  },
  flex_g_from: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "0 14px",
    color: "black",
  },
  input: darkTheme => {
    return {
      background: "transparent",
      border: "none !important",
      color: darkTheme ? "white" : "black",
      position: "absolute",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      top: "0",
      right: "30px",
      margin: "6px 16px",
      textAlign: "right",
      '&:focus': {
        outline: "none",
      }
    }
  },
  redinput: darkTheme => {
    return {
      background: "transparent",
      border: "none !important",
      color:"red",
      position: "absolute",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      top: "0",
      right: "30px",
      margin: "6px 16px",
      textAlign: "right",
      '&:focus': {
        outline: "none",
      }
    }
  },
  tab_bar: darkTheme => {
    return {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        justifyContent: "center",
    }
  },
  tab_logo: {
    margin: "6px",
  },
  tab_logoss: {

  },
  blockbg: {
    background: "rgba(38, 152, 212, 0.2)",
    border: "1px solid #000000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    padding: "14px",
    margin: "0 14px",
  },
  tab_buttons: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "13px",
    margin: "14px",
  },
  btcinfo: {
    fontFamily: 'Montserrat',
    fontWeight: "600",
    fontSize: "12px",
    padding: "0 14px",
  },
  inputtitle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "12px",
    padding: "14px 0 0 0",
    textAlign: "right",
  },
  ititle:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "12px",
    padding: "10px 0 0 0",
    textAlign: "right",
  },  
  ivalue:{
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "12px",
    textAlign: "right",
    display: "flex",
    justifyContent: "end",
  },  
  content: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      borderRadius: "30px 0 0 30px",
      minWidth: "45%",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
      }
    }
  },
  pbutton:  darkTheme => {
    return {
      background: "rgba(55, 236, 64, 0.60)",
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "14px",
      color: darkTheme ? "white" : "black",
      textAlign: "center",
      cursor:"pointer",

      border: "1px solid rgba(176, 203, 217, 0.5)",
      padding: "8px 20px",
      margin: "14px",
    }
  },
}));

const PeachPage = (props) => {
  const { darkTheme, provider } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [initDataUnsafe] = useInitData();
  const [user, setUser] = useState("Buy Crypto");
  const [btcinfo, setBtcinfo] = useState(<div className={classes.btcinfo}>Welcome to AnkerPay, Please open AnkerPay bot and press ENTER to start</div>);
  const [formtype, setFormtype] = useState(0);
  const [checkoutId, setCheckoutId] = useState('');
  const [userId, setUserId] = useState("");
  const [amount, setAmount] = useState('1000');
  const [coin, setCoin] = useState('BTC');
  const [minerfee, setMinerfee] = useState(100);
  const [coinamount, setCoinamount] = useState('0.0000');
  const [price, setPrice] = useState(0);
  const [reccuring, setReccuring] = useState('Yes');
  
  
  const handleAmount = (e) => {
    setAmount(e.target.value);
    setCoinamount( ((Number(e.target.value)-Number(minerfee))/Number(price)).toFixed(8) )
  } 
  
  const changeReccuring = () => {
    if (reccuring == 'Yes') {
      setReccuring('No')
    } else {
      setReccuring('Yes')
    }
    
  }
  
  useEffect(() => {


  }, []);

  
  

  useEffect(() => {
    async function fetchData() {
      if (initDataUnsafe && initDataUnsafe.user) {
        setUser(initDataUnsafe.user.first_name);
        setUserId(initDataUnsafe.user.id);
        let userbtc = await getbtc(initDataUnsafe.user.id);
        if (userbtc) {
          if (userbtc.code == 200) {
            setBtcinfo(
              <>
                  <div className={classes.flex_g}>
                    <div className={classes.ititle}>Crypto</div>
                    <div className={classes.flex_g_select}><div className={classes.ivalue}>{userbtc.coin} <div className={classes.greytitle}> &nbsp; ▼</div></div></div>
                  </div>
                  
                  <div className={classes.flex_g}>
                    <div className={classes.inputtitle}>{userbtc.coin} Address</div>
                    <div className={classes.inputtitle}></div>
                  </div>
                  <div className={classes.flex_forinput}>
                    <div className={classes.flex_g_input}>
                      <div className={classes.greytitle}> </div>
                      <div className={classes.greytitle}> &nbsp; </div>
                    </div>
                    <input type="text" placeholder="" name="btcaddress" className={classes.input}  value={userbtc.address}/>
                  </div>
              </>
            )
            setFormtype(1)
            setMinerfee(userbtc.fee)
            setPrice(Number(userbtc.price))
            setCoinamount( ((Number(amount)-Number(userbtc.fee))/Number(userbtc.price)).toFixed(8) )
          } else if (userbtc.code == 118) {
            setBtcinfo(<div className={classes.btcinfo}>Please Add Your BTC address first</div>)
          } else if (userbtc.code == 83) {
            setBtcinfo(<div className={classes.btcinfo}>Welcome to AnkerPay, Please open AnkerPay bot and press ENTER to start</div>)
          } else {
            setBtcinfo(<div className={classes.btcinfo}>Welcome to AnkerPay, Please open AnkerPay bot and press ENTER to start</div>)
          }
        }
      }
    }
    fetchData();
  }, [initDataUnsafe]);

  useEffect(() => {
    if (checkoutId) {
      setFormtype(2);
    }
  }, [checkoutId]);
  
  return (
    <div className={classes.root}>
      <div className={classes.tab_bar}>
        <div className={classes.tab_logoss}>
          <img className={classes.tab_logo} src="/img/logo.png" width="46" height="46"  />
        </div>
      </div>
      
      
      
      
      <WebAppProvider>
        <div className={classes.blockbg}>
          
            {btcinfo}

            { formtype === 1 && 
              <>
                <div className={classes.flex_g}>
                  <div className={classes.inputtitle}>Amount</div>
                  <div className={classes.inputtitle}></div>
                </div>
                <div className={classes.flex_forinput}>
                  <div className={classes.flex_g_input}>
                    <div className={classes.greytitle}></div>
                    <div className={classes.greytitle}>ZAR</div>
                  </div>
                  <input type="text" placeholder="" name="amount" className={classes.input} onChange={handleAmount} value={amount}/>
                </div>


                <div className={classes.flex_g}>
                  <div className={classes.inputtitle}>Miner Fee</div>
                  <div className={classes.inputtitle}></div>
                </div>
                <div className={classes.flex_forinput}>
                  <div className={classes.flex_g_input}>
                    <div className={classes.greytitle}></div>
                    <div className={classes.greytitle}>ZAR</div>
                  </div>
                  <input type="text" placeholder="" name="minerfee" className={classes.input}  value={minerfee}/>
                </div>

                
                <div className={classes.flex_g}>
                  <div className={classes.inputtitle}>You will receive</div>
                  <div className={classes.inputtitle}>Valid for 30 seconds</div>
                </div>
                <div className={classes.flex_forinput}>
                  <div className={classes.flex_g_input}>
                    <div className={classes.greytitle}></div>
                    <div className={classes.greytitle}>{coin}</div>
                  </div>
                  <input type="text" placeholder="" name="coinamount" className={ Number(coinamount)>0 ? classes.input : classes.redinput} value={coinamount}/>
                </div>

                <div className={classes.flex_g}>
                  <div className={classes.inputtitle}>Monthly recurring payment?</div>
                  <div className={classes.inputtitle}></div>
                </div>
                <div className={classes.flex_forinput}  onClick={changeReccuring} >
                  <div className={classes.flex_g_input}>
                    <div className={classes.greytitle}></div>
                    <div className={classes.greytitle}> &nbsp; ▼</div>
                  </div>
                  <div className={classes.input}>{reccuring}</div>
                </div>
                
                <div className={classes.pbutton} onClick={async () => setCheckoutId(await neworder(userId,amount,reccuring))} >Buy Now</div>

                
              </>
            }
            { formtype === 2 && 
              <>
                <Helmet>
                  
                  <style type="text/css">
                  { darkTheme ? `
                      .wpwl-form { color: white; }
                      .wpwl-control { color: white; }

                  ` : `
                      .wpwl-form { color: black; }
                      .wpwl-control { color: black; }

                  ` }
                  </style>
                  <style type="text/css">
                    {`
                     .wpwl-button-pay {
                        width: 100%;
                        font-style: normal;
                        font-family: Montserrat;
                        font-weight: 700;
                        line-height: 14px;
                        background: rgba(55, 236, 64, 0.60);
                        font-size: 12px;
                        border-radius: 0;
                      }
                      .wpwl-form {
                        background: none;
                        font-family: Montserrat;
                        border: none;
                        box-shadow: none;
                        padding: 14px 0;
                      }
                      .wpwl-label {
                          padding: 14px 0 0 0;
                          font-size: 12px;
                          font-style: normal;
                          font-family: Montserrat;
                          font-weight: 700;
                          line-height: 12px;
                      }
                      .wpwl-label-brand {
                        padding: 10px 10px 0 0;
                        font-size: 12px;
                        font-style: normal;
                        text-align: right;
                        font-family: Montserrat;
                        font-weight: 700;
                        line-height: 12px;
                      }
                      .wpwl-brand {
                        margin: 0;
                      }
                      .wpwl-hint {
                        font-family: Montserrat;
                        font-weight: 700;
                        font-size: 12px;
                      }
                      .wpwl-wrapper-brand {padding-right: 0;}
                      .wpwl-control {
                        background: transparent;
                        background-color: rgba(176, 203, 217, 0.2);
                        border-radius: 0;
                        border: none !important;
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                      }
                      .wpwl-control option {
                        background: transparent;
                        background-color: #415965;
                        border-radius: 0;
                        color: white;
                        border: none !important;
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                      }
                    `}
                  </style>                  
                  <script src={"https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="+checkoutId} type="text/javascript" />
                </Helmet>
                <div className={classes.flex_g_from}>
                  <form action={"https://app.ankerpay.com/checkout-status/"+checkoutId} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
                </div>
              </>
            }
          
        </div>
      </WebAppProvider>
    </div>
  )
}

export default withRouter(PeachPage);
