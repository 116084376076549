import React, {useState} from "react";
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getData } from "../../store/appStoreSlice";
import { IconButton, InputAdornment, Input, TextField, Button } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleLogin from "../SignUpPage/GoogleLogin";
import FBLogin from "../SignUpPage/FBLogin";
import { loginLoc } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#2698D4",
    minHeight: "100vh",
    color: "white",
    display: "flex",
    "@media (max-width: 767.98px)": {
      flexDirection: "column",
    }
  },
  logo: {
    width: "210px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
  },
  logo_img: {
    marginTop: "57px",
  },
  content: darkTheme => {
    return {
      backgroundColor: darkTheme ? "#252525" : "white",
      borderRadius: "30px 0 0 30px",
      minWidth: "45%",
      "@media (max-width: 767.98px)": {
        width: "100%",
        textAlign: "center",
      }
    }
  },
  form: {
    width: "425px",
    margin: "auto",
    marginTop: "42px",
    display: "flex",
    flexDirection: "column",
  },
  create_form: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "300px"
  },
  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField: {
    color: "#E5E5E5!important",
    "&::before": {
      borderBottom: "1px solid #E5E5E5!important",
    }
  },
  password_root: {
    marginTop: "20px!important",
    color: "#E5E5E5!important",
    width: "100%",
    "&::before": {
      borderBottom: "1px solid #E5E5E5!important",
    }
  },
  iconButton_root: {
    color: "#E5E5E5!important",
  },
  have_account: {
    marginTop: "33px",
    fontSize: "15px",
  },
  have_account_link: {
    color: "#167EE6",
    textDecoration: "none",
  },
  social_button: {
    display: "flex",
    marginTop: "45px",
    color: "#E5E5E5",
    "@media (max-width: 767.98px)": {
      flexDirection: "column",
    }
  },
  or: {
    fontSize: "15px",
    marginTop: "30px",
    width: "100%",
    textAlign: "center",
  },
  wrongmessage: {
        color: "red",
  },
  createButton_root: {
    marginTop: "80px!important",
    height: "45px",
    width: "100%",
    backgroundColor: "#2698D4!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "#2698D4!important",
    }
  },
}));

const SignInPage = (props) => {
  const { darkTheme, provider } = useSelector(getData);
  const [wrongmessage, setWrongmessage] = useState("");
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });
  const classes = useStyles(darkTheme);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setWrongmessage("");
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  
  const handleClickLogin = async () => {
    var resp = await loginLoc(values.email,values.password, provider);
    if (resp.data.done != 1) {
      setWrongmessage("The username or password you entered is incorrect");
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <h2 className={classes.title}>AnkerPay<br/>App<br/>Sign in</h2>
        <img className={classes.logo_img} src="/img/logo.png"/>
      </div>
      <div className={classes.content}>
        <div className={classes.form}>
          <h2>Login</h2>
          <div className={classes.social_button}>
            <GoogleLogin/>
            <FBLogin/>
          </div>
          <p className={classes.or}>OR</p>
          <div className={classes.create_form}>
            <TextField 
              id="email" 
              label="Email" 
              type="email" 
              variant="standard"
              onChange={handleChange('email')}
              value={values.email}
              classes={{root: classes.textField_root}}
              InputLabelProps={{classes: {root: classes.textField}}}
              InputProps={{classes: {underline: classes.textField}}}
            />
            <Input
              id="standard-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              placeholder="Password" 
              classes={{root: classes.password_root}}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    classes={{root: classes.iconButton_root}}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <p className={classes.wrongmessage}>{ wrongmessage }</p>
            <Button
              classes={{root: classes.createButton_root}}
              variant="contained" 
              onClick={handleClickLogin}
              color="primary"
            >
              Login
            </Button>
            <p className={classes.have_account}>

              <Link to="/signup" className={classes.have_account_link}>Create account</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(SignInPage);
